import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import SOCreateBulkView from './SOCreateBulk.view';

export type SOCreateBulk = {
  'Tanggal Shipper Order*': string;
  'Nomor Order*': string;
  'Nomor Referensi'?: string;
  'Nomor Resi'?: string;
  'Nama Pengirim*': string;
  Catatan?: string;
  'Aktivitas*': number;
  'Tipe Aktivitas*': string;
  'Tipe Barang'?: string;
  Satuan?: string;
  Keterangan?: string;
  Jumlah?: number;
  'Berat (kg)'?: number;
  'Volume (m3)'?: number;
  'Lokasi*': string;
  'Perkiraan Waktu'?: string;
  'Biaya Kirim Barang'?: number;
  Pajak?: number;
  Asuransi?: number;
};

export type SOCreateBulkRouteParam = {
  filename: string;
  shipperOrders: SOCreateBulk[];
};

export const soCreateBulkRoute: RouteConfig = {
  name: 'Import Shipper Order',
  Component: React.lazy(() => import('./SOCreateBulk.view')),
  NormalComponent: <SOCreateBulkView />,
  path: '/shipper-order/create-bulk',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
