import { Row, RowData } from '@tanstack/react-table';
import { LegacyRef } from 'react';
import { TwStyle } from 'twin.macro';
import { ExtraStyle } from '../component/Type.component';
import { ActivityTemplate } from '../model/ActivityTemplate.model';
import { HaulingOrder } from '../model/HaulingOrder.model';
import { Location } from '../model/Location.model';
import { ShipperOrderCreateBulkTableError } from '../model/ShipperOrder.model';
import { ErrorDetail } from '../service/api.endpoint';

// #region INTERFACES
export enum BulkActionStatusEnum {
  WAITING = 'WAITING',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
}

export type BulkActionEntity<T> = T & {
  _actionDetail: string | ErrorDetail[];
  _actionStatus: BulkActionStatusEnum;
  _actionActivityError?: ShipperOrderCreateBulkTableError | null;
};

export type DeleteDeliveryLocationEntity = BulkActionEntity<Location>;
export type DeleteActivityTemplateEntity = BulkActionEntity<ActivityTemplate>;
export type ForceCompleteHaulingOrderEntity = BulkActionEntity<HaulingOrder>;

// #region INTERFACES
export type ColumnMeta =
  | {
      rowSpan?: number;
      headerStyle?: TwStyle;
      cellStyle?: TwStyle;
      sizeOffset?: number;
      specificCellStyle?: (id: string) => TwStyle | false;
    }
  | undefined;

export type FooterItem = {
  id: string;
  node: React.ReactNode;
  cellStyle?: React.CSSProperties;
};

export type FooterOptions = {
  ref: LegacyRef<HTMLDivElement>;
  data: FooterItem[];
  containerStyle?: React.CSSProperties;
  onScrollTableContainer?: (ev: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

export type InfiniteScrollingOptions = React.CSSProperties & {
  onScrollTableContainer: (ev: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  useVirtualizer: boolean;
};

export type RenderSubComponentProps<RD extends RowData> = {
  row: Row<RD>;
};

export type TableStickyProps = {
  stickyHeader?: boolean;
  stickyHeaderPositionTop?: number;
  stickyColumn?: boolean;
  stickyColumnNumber?: number;
};

export type TableStyleProps = {
  rootStyle?: ExtraStyle;
  tableContainerStyle?: ExtraStyle;
  paginationContainerStyle?: ExtraStyle;
  expandedRowStyle?: ExtraStyle;
};
// #endregion

// #endregion

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const REPORT_REVENUE_DEFAULT_PAGE_SIZE = 12;
export const HAULING_JO_DEFAULT_PAGE_SIZE = 50;
