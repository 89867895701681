import { useCallback, useState } from 'react';

type SOListInfoModal = {
  soNumber: string[];
  failedSONumber?: string[];
  isRequestCompleted?: boolean;
};

export default function useJOSOListModal() {
  const [soListInfoModal, setSoListInfoModal] = useState<
    SOListInfoModal | undefined
  >(undefined);

  const onOpenSoListModal = useCallback(
    ({ soNumber, failedSONumber, isRequestCompleted }: SOListInfoModal) => {
      setSoListInfoModal({ soNumber, failedSONumber, isRequestCompleted });
    },
    [],
  );

  const onCloseSoListModal = useCallback(() => {
    setSoListInfoModal(undefined);
  }, []);

  return {
    soListInfoModal,
    onOpenSoListModal,
    onCloseSoListModal,
  };
}

export type UseJOSOListModal = ReturnType<typeof useJOSOListModal>;
