import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfToday from 'date-fns/startOfToday';
import { FormikState } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { ValidateOptions } from 'yup/lib/types';
import {
  LastMile,
  StartFromScratch,
  UseTemplate,
} from '../component/atom/Icon/Icon.atom';
import {
  SOActivityConfirmationMapper,
  SOActivitySelectionType,
  SOActivityType,
} from '../constant';
import { AutocompleteType } from '../hook/useAutocomplete.hook';
import { UseTranslator } from '../hook/useTranslator.hook';
import { JobOrderForm } from '../model/JobOrder.model';
import {
  Goods,
  ShipperOrderActivity,
  ShipperOrderActivityFormValues,
  ShipperOrderCost,
  ShipperOrderCostFormValues,
  ShipperOrderCreateParam,
  ShipperOrderFormValues,
  SOActivityFormProps,
  SOCreateBulkParamsActivityGoodsData,
  SOUpdateActivitiesProps,
} from '../model/ShipperOrder.model';
import { PostJobOrderCreateParams } from '../service/endpoint/jobOrder/jobOrder.endpoint';
import { UseSOActivityTemplateForm } from '../view/ShipperOrderCreate/hooks/useSOActivityTemplateForm.hook';
import { UseSOATCreateForm } from '../view/ShipperOrderCreate/hooks/useSOATCreateForm.hook';
import { UseSOCreateAT } from '../view/ShipperOrderCreate/hooks/useSOCreateAT.hook';
import { UseSOCreateController } from '../view/ShipperOrderCreate/hooks/useSOCreateController.hook';
import { promiseToVoid } from './helper.util';

const MAX_NUMBER_VALUE = 999999999.99;
const MIN_NUMBER_VALUE = 1;

export const soInitialValues: ShipperOrderFormValues = {
  activities: [],
  cost: undefined,
  referenceNumber: '',
  shipperId: '',
  soDate: getUnixTime(startOfToday()),
  soNumber: '',
  notes: '',
};

export function validateTrackingCode(fieldName: string, skip?: boolean) {
  return (value?: string) => {
    if (!value || skip) return true;
    if (/(^TRCK-)|(^trck-)|(^HTRCK-)|(^htrck-)/g.test(value)) {
      return new yup.ValidationError(
        'Manual Tracking number cannot be started with char "TRCK-","trck-","HTRCK-" or "htrck-"',
        value,
        fieldName,
      );
    }
    return true;
  };
}

export const getTestMinimumNumberValue = (val: string | undefined) =>
  (typeof val === 'string' && parseFloat(val) >= MIN_NUMBER_VALUE) || !val;

export const getTestMaximumNumberValue = (val: string | undefined) =>
  (typeof val === 'string' && parseFloat(val) <= MAX_NUMBER_VALUE) || !val;

/**
 * compare initial values (values from remote response) to updated values
 * @param translator
 * @param prev
 * @returns
 */
const compareAgainstRemoteValue =
  (translator: UseTranslator, prev?: Partial<Goods>[]) =>
  (
    value: string | undefined,
    { createError, options, path }: yup.TestContext,
  ) => {
    const option = options as ValidateOptions & {
      index: number;
    };
    if (prev) {
      const initialValue = prev[option.index];
      const key = path.substring(
        path.lastIndexOf('.') + 1,
      ) as keyof Partial<Goods>;

      if (initialValue[key] !== null && !value) {
        return createError({
          message: translator.translate(
            'Prefilled data is not allowed to be empty.',
          ),
          path,
          type: key,
        });
      }

      return true;
    }
    return true;
  };

/**
 * This helper is for get goods validation schema
 * @param translator
 * @returns
 */

export const getGoodsValidationSchema = (
  translator: UseTranslator,
  prev?: Partial<Goods>[],
) =>
  yup.object().shape({
    type: yup
      .string()
      .required(translator.translate('Goods type is required.'))
      .nullable(),
    description: yup
      .string()
      .optional()
      .max(
        1000,
        translator.translate('Description must be at most 1000 characters.'),
      )
      .nullable(),
    quantity: yup
      .string()
      .test('quantity', '', compareAgainstRemoteValue(translator, prev))
      .test(
        'quantity',
        translator.translate('Maximum value is 999.999.999.'),
        getTestMaximumNumberValue,
      )
      .test(
        'quantity',
        translator.translate('Minimum value is 1.'),
        getTestMinimumNumberValue,
      )
      .optional()
      .nullable(),
    uom: yup
      .string()
      .optional()
      .max(50, translator.translate('Maximum 50 characters.'))
      .nullable(),
    volume: yup
      .string()
      .optional()
      .test('volume', '', compareAgainstRemoteValue(translator, prev))
      .test(
        'volume',
        translator.translate('Maximum value is 999.999.999.'),
        getTestMaximumNumberValue,
      )
      .test(
        'volume',
        translator.translate('Minimum value is 1.'),
        getTestMinimumNumberValue,
      )
      .nullable(),
    weight: yup
      .string()
      .optional()
      .test('weight', '', compareAgainstRemoteValue(translator, prev))
      .test(
        'weight',
        translator.translate('Maximum value is 999.999.999.'),
        getTestMaximumNumberValue,
      )
      .test(
        'weight',
        translator.translate('Minimum value is 1.'),
        getTestMinimumNumberValue,
      )
      .nullable(),
  });

/**
 * This helper is for get goods table total
 * @param totalOf
 * @param activity
 * @returns
 */

export const getGoodsTableTotal = (
  totalOf: 'volume' | 'weight',
  activity?: Partial<Goods>[],
) => {
  let result = 0;
  let filteredArr = [];
  if (!activity) return result;
  filteredArr = activity
    .filter(
      (v: Partial<Goods>) =>
        (typeof v[totalOf] === 'string' || typeof v[totalOf] === 'number') &&
        !!v[totalOf] &&
        !Number.isNaN(parseFloat(String(v[totalOf]))),
    )
    .map((v: Partial<Goods>) =>
      parseFloat(String(v[totalOf]).replace(',', '.')),
    );
  if (filteredArr.length > 0)
    result = filteredArr.reduce((prev, curr) => prev + curr);

  return result;
};

/**
 * This helper is for get total goods from total
 * @param goods
 * @param key
 * @returns
 */

export const getGoodsFormTotal = (
  goods: Partial<Goods>[],
  key: 'weight' | 'volume',
): number => {
  try {
    if (goods) {
      return goods
        .map((v) => {
          if (!v) return 0;
          return v[key] ? parseFloat(String(v[key]).replace(',', '.')) : 0;
        })
        .filter((v) => !!v)
        .reduce((prev, next) => prev + next);
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

export function validateShipperOrder(fieldName: string) {
  return (value?: string) => {
    if (!value) return true;
    if (/^SH-/g.test(value)) {
      return new yup.ValidationError(
        'Manual SO number cannot be started with char "SH-"',
        value,
        fieldName,
      );
    }
    return true;
  };
}

/**
 * This helper is for get so head section validation schema
 * @param translator
 * @returns
 */
export const getHeadSectionScheme = (translator: UseTranslator) => ({
  notes: yup
    .string()
    .optional()
    .max(1000, translator.translate('Maximum 1000 characters')),
  soNumber: yup
    .string()
    .max(255, translator.translate('Max 255 characters.'))
    .test('soNumber', {}, validateShipperOrder('soNumber'))
    .optional(),
  soDate: yup
    .string()
    .required(translator.translate('Shipper date is required')),
  shipperId: yup
    .string()
    .required(translator.translate('Shipper name is required.')),
  trackingCode: yup
    .string()
    .test('trackingCode', {}, validateTrackingCode('trackingCode'))
    .max(255, translator.translate('Max 255 characters.'))
    .optional(),
  referenceNumber: yup
    .string()
    .max(255, translator.translate('Max 255 characters.'))
    .optional(),
});

/**
 * This helper is for get so cost section validation schema
 * @param translator
 * @returns
 */
export const getSOCostSectionScheme = (translator: UseTranslator) => ({
  deliveryFee: yup
    .string()
    .optional()
    .test(
      'deliveryFee',
      translator.translate('Maximum value is 999.999.999.'),
      getTestMaximumNumberValue,
    ),

  insurance: yup
    .string()
    .optional()
    .test(
      'insurance',
      translator.translate('Maximum value is 999.999.999.'),
      getTestMaximumNumberValue,
    ),
  tax: yup
    .string()
    .optional()
    .test(
      'tax',
      translator.translate('Maximum value is 999.999.999.'),
      getTestMaximumNumberValue,
    ),
});

/**
 * This helper is for get cost section validation schema
 * @param translator
 * @returns
 */

export const getCostSectionScheme = (translator: UseTranslator) => ({
  cost: yup.object().shape(getSOCostSectionScheme(translator)),
});

/**
 * This helper is for get activity section validation schema
 * @param translator
 * @returns
 */
export const getActivitiesSectionScheme = (translator: UseTranslator) => ({
  activities: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().required(translator.translate('Type is required.')),
        expectedFinishAt: yup.string().nullable(),
        index: yup.number().defined(),
        locationId: yup
          .string()
          .required(translator.translate('Location is required.'))
          .nullable(),
        goods: yup.array().of(getGoodsValidationSchema(translator)),
      }),
    )
    .min(1, translator.translate('Activity is required atleast 1')),
});

/**
 * This helper is for get label from activity location type
 * @param type
 * @returns
 */

export const getActivityLocationLabelByType = (type?: string) => {
  if (type === SOActivityType.STAND_BY) return 'Standby Location';
  if (type === SOActivityType.PICK_UP) return 'Pickup Location';
  return 'Dropoff Location';
};

/**
 * This helper is for transform shipper order activity form values to autocomplete type
 * @param activities
 * @param index
 * @returns
 */

export const getActivityLocationOption = (
  activities: SOUpdateActivitiesProps[],
  index: number,
): AutocompleteType | undefined => {
  const selectedActivity = activities[index];
  if (!!selectedActivity?.location?.name && !!selectedActivity?.location?.id)
    return {
      label: selectedActivity.location?.name || '',
      value: selectedActivity.location?.id || '',
    };
  return undefined;
};

/**
 * Convert goods `Partial<Goods>` form values to `Goods` as acceptable interace to the backend
 * @param goods
 * @returns
 */
export const convertGoodsFormValues = (goods: Partial<Goods>[]): Goods[] =>
  (goods as Goods[]).map(
    ({ quantity, volume, weight, description, uom, ...rest }) => ({
      ...rest,
      ...(uom && { uom: uom.trim() }),
      ...(description && { description: description.trim() }),
      ...(quantity && { quantity: parseInt(String(quantity), 10) }),
      ...(volume && { volume: parseFloat(String(volume).replace(',', '.')) }),
      ...(weight && { weight: parseFloat(String(weight).replace(',', '.')) }),
    }),
  );

export const activitiesFormatter = (
  activities?: ShipperOrderActivityFormValues[],
  isTransitable?: boolean,
): ShipperOrderActivity[] => {
  if (!activities) return [];
  if (isTransitable)
    return activities.map(
      ({ locationId, type, expectedFinishAt }, index, arr) => ({
        ...(expectedFinishAt && { expectedFinishAt: Number(expectedFinishAt) }),
        index,
        locationId: locationId || '',
        type,
        ...(!!arr.length &&
          !!arr[0].goods.length &&
          typeof index === 'number' && {
            goods: arr[0].goods,
          }),
      }),
    );
  return activities.map(
    ({ locationId, goods, type, expectedFinishAt }, index) => ({
      ...(expectedFinishAt && { expectedFinishAt: Number(expectedFinishAt) }),
      index,
      locationId: locationId || '',
      type,
      ...(!!goods.length && {
        goods,
      }),
    }),
  );
};

export const soCostFormatter = (
  cost?: ShipperOrderCostFormValues,
): ShipperOrderCostFormValues => {
  const result: ShipperOrderCostFormValues = {};
  if (cost) {
    for (const v of Object.keys(cost)) {
      const key = v as keyof ShipperOrderCostFormValues;
      if (cost[key]) {
        result[key] = parseInt(String(cost[key]), 10);
      }
    }
  }
  return result;
};

export const soCreateParamsFormatter = (
  formValues: ShipperOrderFormValues,
): ShipperOrderCreateParam => {
  const activities: ShipperOrderActivity[] = activitiesFormatter(
    formValues.activities,
    formValues.isTransitable,
  );

  return {
    shipperId: formValues.shipperId || '',
    soDate: formValues.soDate as number,
    isTransitable: formValues.isTransitable,
    cost: soCostFormatter(formValues.cost) as ShipperOrderCost,
    ...(formValues.notes && { notes: formValues.notes.trim() }),
    ...(formValues.trackingCode && {
      trackingCode: formValues.trackingCode.trim(),
    }),
    ...(formValues.referenceNumber && {
      referenceNumber: formValues.referenceNumber.trim(),
    }),
    ...(formValues.soNumber && { soNumber: formValues.soNumber.trim() }),
    activities,
  };
};

export const soCreateGetTotalCost = (cost?: ShipperOrderCost) => {
  if (!cost) return 0;

  return (Object.values(cost) as string[])
    .map((v) => (v ? parseInt(v.replace(/\D/i, ''), 10) : 0))
    .reduce((prev, next) => prev + next);
};

export const soActivityTemplateValidationSchema = (
  isTemplateRequireSave: boolean,
) =>
  yup.object().shape({
    name: isTemplateRequireSave
      ? yup
          .string()
          .required('Activity template name is required.')
          .min(3, 'Activity template name must be atleast 3 characters.')
          .max(100, 'Activity template name must be atmost 100 characters.')
      : yup.string().notRequired(),
    description: isTemplateRequireSave
      ? yup
          .string()
          .max(300, 'Description must be atmost 300 characters.')
          .optional()
      : yup.string().notRequired(),
  });

export const soAssignFormatter = (
  soId: string,
  joValue: JobOrderForm,
): PostJobOrderCreateParams => ({
  deliveries: [{ soId }],
  driverId: joValue.driverId || '',
  vehicleId: joValue.vehicleId || '',
  joDate: joValue?.joDate ? joValue?.joDate : 0,
  ...(joValue.sealNumber && { sealNumber: joValue.sealNumber }),
  ...(joValue.travelExpenses && { travelExpense: joValue.travelExpenses }),
});

export const getSOActivitySelectionTypeIllustration = (
  type: SOActivitySelectionType,
) => {
  const types: Record<string, React.ReactNode> = {
    [SOActivitySelectionType.SCRATCH]: <LastMile />,
    [SOActivitySelectionType.TRANSIT]: <StartFromScratch />,
    [SOActivitySelectionType.TEMPLATE]: <UseTemplate />,
    DEFAULT: <UseTemplate />,
  };

  return types[type] || types.DEFAULT;
};

export const getSOActivitySelectionTypeLabel = (
  type: SOActivitySelectionType,
) => {
  const types: Record<string, string> = {
    [SOActivitySelectionType.SCRATCH]: 'Last Mile',
    [SOActivitySelectionType.TRANSIT]: 'Transit',
    [SOActivitySelectionType.TEMPLATE]: 'Use Template',
    DEFAULT: 'Use Template',
  };

  return types[type] || types.DEFAULT;
};

export const getSODropoffTransitExpectedTimeDate = (
  soDate?: number,
  pickupDate?: number,
): Date => {
  if (!soDate) return new Date();
  if (!pickupDate) return fromUnixTime(soDate);
  return fromUnixTime(pickupDate);
};

export const getSODropoffTransitExpectedTime = (
  soDate?: number,
  pickupDate?: number,
): { from: string; to: string } | undefined => {
  const from = '00:00';
  if (!soDate) return undefined;
  if (!pickupDate)
    return { from, to: format(addMinutes(fromUnixTime(soDate), 1), 'HH:mm') };
  return { from, to: format(addMinutes(fromUnixTime(pickupDate), 1), 'HH:mm') };
};

export const getModalConfirmationTitleFromModalType = (
  modalType?: SOActivitySelectionType,
) => {
  if (!modalType) return '';
  const question = 'Are you sure you want to';

  const mapper = {
    ...SOActivityConfirmationMapper,
    [SOActivitySelectionType.CHANGE_TEMPLATE]: 'change the Activity Template',
  };
  return `${question} ${mapper[modalType] || ''}?`;
};

export const getModalConfirmationActionFromModalType = (
  modalType?: SOActivitySelectionType,
) => {
  if (!modalType) return '';
  const mapper = {
    [SOActivitySelectionType.SCRATCH]: 'Use Last Mile Form',
    [SOActivitySelectionType.TEMPLATE]: 'Use Template',
    [SOActivitySelectionType.TRANSIT]: 'Use Transit Form',
    [SOActivitySelectionType.CHANGE_TEMPLATE]: 'Browse Activity Template',
  };
  return mapper[modalType] || '';
};

export const getModalConfirmationDescriptionFromModalType = (
  modalType?: SOActivitySelectionType,
) => {
  if (modalType === SOActivitySelectionType.TEMPLATE)
    return 'Changing the input method to Use Template will deleted all the activities you have created. This action cannot be undone.';

  return 'The created activities will be deleted. This action cannot be undone.';
};

export const generateSOCreateActivityFormData = ({
  translator,
  formState,
  soCreateAT,
  soCreateController,
  soActivityTemplateForm,
  soActivityTemplateCreateForm,
  handleResetVehicle,
}: {
  translator: UseTranslator;
  formState: FormikState<ShipperOrderFormValues>;
  soCreateAT: UseSOCreateAT;
  soCreateController: UseSOCreateController;
  soActivityTemplateCreateForm: UseSOATCreateForm;
  soActivityTemplateForm: UseSOActivityTemplateForm;
  handleResetVehicle: () => void;
}): SOActivityFormProps => ({
  actionLabel: translator.translate('Next'),
  activities: formState.values?.activities,
  activitiesError: formState.errors?.activities,
  activityName: soActivityTemplateCreateForm.selectedAT?.name,
  isActionDisabled: soActivityTemplateForm.isSubmitActivityDisabled,
  isActivityFromTemplate:
    soActivityTemplateCreateForm.activityTypeSelected &&
    [
      SOActivitySelectionType.TEMPLATE,
      SOActivitySelectionType.CHANGE_TEMPLATE,
    ].includes(soActivityTemplateCreateForm.activityTypeSelected) &&
    !!soActivityTemplateCreateForm.selectedAT?.name,
  isCreateATInfoVisible: true,
  isFooterVisible: soCreateController.formStep === 2,
  isLocationListFetchLoading: soCreateAT.locationListFetchLoading,
  isTemplateRequireSave: soActivityTemplateForm.isTemplateRequireSave,
  isTemplateRequireSaveDisabled:
    soActivityTemplateCreateForm.isTemplateRequireSaveDisabled,
  locationOptions: soCreateAT.locationOptions,
  soActivityTemplateFormData: soActivityTemplateForm.soActivityTemplateFormData,
  soDate: formState.values?.soDate,
  title: `(2/${soCreateController.totalFormStep}) ${translator.translate(
    'Activity',
  )}`,
  handleAction: soCreateController.handleSubmitHeaderForm(2),
  handleAddNewLocation: (idx) =>
    soActivityTemplateCreateForm.handleAddNewLocation(idx),
  handleAddMoreActivity: soActivityTemplateCreateForm.handleAddMoreActivity,
  handleChangeActivity: () => {
    handleResetVehicle();
    promiseToVoid([
      soActivityTemplateCreateForm.handleSelectConfirmationType(
        SOActivitySelectionType.CHANGE_TEMPLATE,
      ),
    ]);
  },
  handleChangeDataLocation: soCreateAT.handleChangeDataLocation,
  handleChangeDateActivity:
    soActivityTemplateCreateForm.handleChangeDateActivity,
  handleChangeLocationAutotext: soCreateAT.handleChangeLocationAutotext,
  handleChangeSaveTemplate: (e) =>
    soActivityTemplateForm.handleChangeSaveTemplate(e),
  handleClickChoice: soActivityTemplateCreateForm.handleClickChoice,
  handleClickEditActivity: soCreateAT.onActivityEditClick,
  handleFetchMoreLocation: soCreateAT.handleFetchMoreLocation,
  handleDuplicateActivity: async (e) => {
    handleResetVehicle();
    await soActivityTemplateCreateForm.handleDuplicateActivity(e);
  },
  handleRemoveActivity: async (e) => {
    handleResetVehicle();
    await soActivityTemplateCreateForm.handleRemoveActivity(e);
  },
  handleRemoveLocation: soActivityTemplateCreateForm.handleRemoveLocation,
  handleShowActivitySelectionMethod:
    soActivityTemplateCreateForm.handleShowActivitySelectionMethod,
  handleCancelActivitySelectionMethod:
    soActivityTemplateCreateForm.handleCancelActivitySelectionMethod,
  getActivityFormErrors: soCreateAT.getActivityFormErrors,
});

export const transformActivityBulkToGoods = (
  bulkGoods?: SOCreateBulkParamsActivityGoodsData[],
): Goods[] => {
  if (!bulkGoods) return [];
  return bulkGoods.map((goods) => ({
    ...goods,
    quantity: goods.quantity || 0,
    volume: goods.volume || 0,
    weight: goods.weight || 0,
  }));
};
