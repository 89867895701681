import useTranslator from '../../hook/useTranslator.hook';
import useShipperOrderUploadModal from '../ShipperOrder/hooks/useShipperOrderUploadModal.hook';
import useSOActivityListModal from './hooks/useSOActivityListModal.hook';
import useSOCreateBulkLogic from './hooks/useSOCreateBulkLogic.hook';
import useSOCreateBulkTable from './hooks/useSOCreateBulkTable.hook';

export default function useSOCreateBulkViewModel() {
  const translator = useTranslator();

  const activitiesModal = useSOActivityListModal();
  const uploadModal = useShipperOrderUploadModal();
  const bulkLogic = useSOCreateBulkLogic();
  const bulkTable = useSOCreateBulkTable({ bulkLogic, activitiesModal });

  return {
    translator,
    uploadModal,
    activitiesModal,
    bulkLogic,
    bulkTable,
  };
}
