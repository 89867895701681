import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { ErrorDetail } from '../../../service/api.endpoint';
import { getDateFormat } from '../../../util/date.util';
import { numberFormatter } from '../../../util/formatter.util';
import {
  mapBulkActionStatusToLabel,
  mapErrorMessages,
} from '../../../util/helper.util';
import { UseSOActivityListModal } from './useSOActivityListModal.hook';
import {
  CreateBulkSOEntity,
  UseSOCreateBulkLogic,
} from './useSOCreateBulkLogic.hook';

// #region TYPES
type Props = {
  bulkLogic: UseSOCreateBulkLogic;
  activitiesModal: UseSOActivityListModal;
};
// #endregion

export default function useSOCreateBulkTable({
  bulkLogic: { showStatus, shipperOrdersWithStatusDetail },
  activitiesModal: { onOpenActivityListModal },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<CreateBulkSOEntity>[]>(
    () => [
      {
        size: 130,
        header: translator.translate('SO Number'),
        accessorKey: 'soNumber',
        cell: ({ getValue }) => {
          const soNumber = getValue() as string;

          return (
            <CustomTableRowWithPopover
              tooltipWithPortal
              primaryLabel={soNumber ?? '-'}
              containerStyle={tw`max-w-[148px]`}
            />
          );
        },
      },
      {
        size: 130,
        header: translator.translate('SO Date'),
        accessorKey: 'soDate',
        cell: ({ getValue }) => {
          const soDate = getValue() as number;

          return (
            <CustomTableRowWithPopover
              tooltipWithPortal
              primaryLabel={soDate ? getDateFormat(fromUnixTime(soDate)) : '-'}
              containerStyle={tw`max-w-[144px]`}
            />
          );
        },
      },
      {
        size: 140,
        header: translator.translate('Tracking Number'),
        accessorKey: 'trackingCode',
        cell: ({ getValue }) => {
          const trackingCode = getValue() as string;

          return (
            <CustomTableRowWithPopover
              tooltipWithPortal
              primaryLabel={trackingCode ?? '-'}
              containerStyle={tw`max-w-[154px]`}
            />
          );
        },
      },
      {
        size: 130,
        header: translator.translate('Ref Number'),
        accessorKey: 'referenceNumber',
        cell: ({ getValue }) => {
          const referenceNumber = getValue() as string;

          return (
            <CustomTableRowWithPopover
              tooltipWithPortal
              primaryLabel={referenceNumber ?? '-'}
              containerStyle={tw`max-w-[144px]`}
            />
          );
        },
      },
      {
        size: 130,
        header: translator.translate('Shipper Name'),
        accessorKey: 'shipperName',
        cell: ({ getValue }) => {
          const shipperName = getValue() as string;

          return (
            <CustomTableRowWithPopover
              tooltipWithPortal
              primaryLabel={shipperName ?? '-'}
              containerStyle={tw`max-w-[144px]`}
            />
          );
        },
      },
      {
        size: 170,
        header: translator.translate('Total Activity'),
        accessorFn: (shipperOrder) => shipperOrder,
        cell: ({ getValue }) => {
          const shipperOrder = getValue() as CreateBulkSOEntity;
          const totalActivity = shipperOrder.activities.length;
          return (
            <Text.Paragraph
              onClick={() =>
                onOpenActivityListModal({
                  activities: shipperOrder.activities,
                  isRequestCompleted:
                    shipperOrder._actionStatus !== BulkActionStatusEnum.WAITING,
                  failedActivities: shipperOrder._actionActivityError,
                })
              }
              tw="cursor-pointer text-orange font-bold"
            >{`${totalActivity} ${translator.translate(
              'Activities',
            )}`}</Text.Paragraph>
          );
        },
      },
      {
        size: 170,
        header: translator.translate('Total Cost'),
        accessorFn: (shipperOrder) => shipperOrder,
        cell: ({ getValue }) => {
          const shipperOrder = getValue() as CreateBulkSOEntity;
          const totalCost = Object.values(shipperOrder.cost).reduce(
            (sum, value) => sum + (value || 0),
            0,
          );

          return (
            <CustomTableRowWithPopover
              tooltipWithPortal
              primaryLabel={`Rp ${numberFormatter(totalCost ?? 0)}`}
              containerStyle={tw`max-w-[144px]`}
            />
          );
        },
      },
      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => string | ErrorDetail[] }) => {
                const accessor = getValue();
                const label =
                  typeof accessor === 'string'
                    ? translator.translate(accessor)
                    : mapErrorMessages(translator, accessor);

                return (
                  <CustomTableRowWithPopover
                    tooltipWithPortal
                    primaryLabel={label}
                    containerStyle={tw`max-w-[290px]`}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [showStatus, translator, onOpenActivityListModal],
  );

  const table = useReactTable({
    data: shipperOrdersWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
