import React from 'react';
import tw, { styled } from 'twin.macro';
import { HelpCenterItemProps } from '../../../constant/HelpCenter.constant';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { Image } from '../../atom';
import { BodyFourteen } from '../../atom/Text/Text.atom';
import { InfoAlert } from '../../organism';

const Container = tw.div`flex flex-col gap-1`;
const Index = tw(BodyFourteen)`ml-2`;
const Content = tw.div`ml-6 flex flex-col gap-4`;
const DescriptionContainer = tw(BodyFourteen)`whitespace-pre-wrap`;
const ImageSection = styled.div(
  ({ isImageContainerFlexCol }: { isImageContainerFlexCol?: boolean }) => [
    tw`flex gap-4 `,
    isImageContainerFlexCol && tw`flex-col`,
  ],
);
const ImageContainer = styled(Image)(
  ({
    isMobile,
    isImageContainerFlexCol,
  }: { isMobile?: boolean; isImageContainerFlexCol?: boolean }) => [
    tw`flex flex-1 max-h-[360px] border border-beige-lines rounded object-contain bg-neutral-50`,
    isMobile && tw`max-h-[171px] w-2.5`,
    isImageContainerFlexCol && tw`w-full`,
  ],
);
const LabelContainer = tw.div`flex gap-2`;

type Props = HelpCenterItemProps & {
  idx: number;
  testID?: string;
};

// Extract Table component
function HelpCenterTable({ tableRows }: { tableRows: Props['tableRows'] }) {
  if (!tableRows) return null;

  return (
    <table style={{ borderSpacing: 0 }} tw="w-full table-fixed border-separate">
      {!!tableRows.header.length && (
        <thead>
          <tr>
            <td tw="w-16 p-3 bg-beige-bg border border-beige-lines text-black-primary font-bold first-of-type:rounded-tl">
              No.
            </td>
            {tableRows.header.map((header, index, arr) => (
              <td
                key={`help-center-table-header-${index}-${header}`}
                tw="break-words p-3 bg-beige-bg border border-beige-lines text-black-primary font-bold border-l-0"
                css={index === arr.length - 1 ? tw`rounded-tr` : {}}
              >
                {header}
              </td>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {tableRows.datas.map((data, rowIndex) => (
          <tr key={`help-center-table-row-${rowIndex}`}>
            <td
              tw="w-16 p-3 border border-beige-lines border-t-0 content-start"
              css={
                rowIndex === tableRows.datas.length - 1 ? tw`rounded-bl` : {}
              }
            >
              {rowIndex + 1}.
            </td>
            {data.map((item, colIndex) => (
              <td
                key={`help-center-table-data-${colIndex}-${item}`}
                tw="break-words p-3 border border-beige-lines border-t-0 border-l-0 whitespace-pre-wrap content-start"
                css={
                  rowIndex === tableRows.datas.length - 1 &&
                  colIndex === data.length - 1
                    ? tw`rounded-br`
                    : {}
                }
              >
                {item}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// Extract Images component
function ImageGallery({
  images,
  isImageContainerFlexCol,
  isMobile,
  testID,
}: {
  images: Props['images'];
  isImageContainerFlexCol?: boolean;
  isMobile: boolean;
  testID?: string;
}) {
  if (!images?.length) return null;

  return (
    <ImageSection isImageContainerFlexCol={isImageContainerFlexCol}>
      {images.map((item, index) => (
        <ImageContainer
          isImageContainerFlexCol={isImageContainerFlexCol}
          onClick={() => item.onClick?.(item.url)}
          isMobile={isMobile}
          css={item.style}
          key={`help-center-image-data-layout-${testID || ''}-${
            item.url || index
          }`}
          src={item.url}
        />
      ))}
    </ImageSection>
  );
}

export default function HelpCenterDataLayout({
  idx,
  testID,
  description,
  alert,
  isImageContainerFlexCol,
  images,
  tableRows,
}: Props) {
  const { isMobile } = useScreenDetection();

  const renderAlert = (position?: string) => {
    if (!alert || (position && alert.position !== position)) return null;

    return (
      <InfoAlert
        rootStyle={alert.style}
        title={alert?.title}
        description={alert.description}
        rules={alert?.ruleItems}
      />
    );
  };

  return (
    <Container>
      <LabelContainer>
        <Index>{idx}. </Index>
        <DescriptionContainer>{description}</DescriptionContainer>
      </LabelContainer>
      <Content>
        {renderAlert('start')}
        <ImageGallery
          images={images}
          isImageContainerFlexCol={isImageContainerFlexCol}
          isMobile={isMobile}
          testID={testID}
        />
        <HelpCenterTable tableRows={tableRows} />
        {renderAlert('end')}
      </Content>
    </Container>
  );
}
