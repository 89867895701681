import React from 'react';
import { toast } from 'react-toastify';
import tw from 'twin.macro';
import { Icon, IconButton, Text } from '../component/atom';
import { CustomTableRowWithPopoverProps } from '../component/molecule/CustomTableRowWithPopover/CustomTableRowWithPopover.molecule';
import {
  OrderByValue,
  OrderStatusValue,
  PaymentStatusValue,
  ShipperOrderActivityEventType,
  SOActivityGoodsType,
  SOActivityType,
  SOOrderBy,
  SOOrderStatus,
  SOPaymentStatusLabel,
  SOStatus,
} from '../constant';
import { UseTranslator } from '../hook/useTranslator.hook';
import { JobOrderDeliveryStatus, JOStatus } from '../model/JobOrder.model';
import {
  JOSOCandidateFilterFormStatus,
  SOActivityDetailNote,
  SOListActivity,
  SOPaymentStatus,
} from '../model/ShipperOrder.model';
import { shipperOrderRoute } from '../view/ShipperOrder/ShipperOrder.route';
import {
  createShipperOrderRoute,
  ShipperOrderCreateNavigateParams,
} from '../view/ShipperOrderCreate/ShipperOrderCreate.route';
import { DeleteBulkShipperOrderEntity } from '../view/ShipperOrderDeleteBulk/hooks/useShipperOrderDeleteBulkLogic.hook';
import { errorCodeToLabel } from './error.util';

// #region INTERFACES
type MapSOActivitiesToTableRowDataOutput = {
  pickUpData: CustomTableRowWithPopoverProps;
  dropOffData: CustomTableRowWithPopoverProps;
};
// #endregion

// #region STYLED
const ErrorContainer = tw.aside`bg-status-alert-light text-status-alert flex justify-between items-center`;
const ErrorContent = tw(Text.BodyOne)`text-current p-4 flex-1`;
const ErrorCloseButton = tw(IconButton)`
  text-current mr-2 self-center relative
  hover:(bg-transparent after:(content-[' '] absolute inset-0 bg-current rounded-full opacity-10))
`;
// #endregion

export const getActivityGoodsTypeLabel = (type: SOActivityGoodsType) => {
  const types: Record<string, string> = {
    [SOActivityGoodsType.CLOTHES]: 'Clothes',
    [SOActivityGoodsType.DOCUMENT]: 'Document',
    [SOActivityGoodsType.ELECTRONIC]: 'Electronic',
    [SOActivityGoodsType.FOOD]: 'Food',
    [SOActivityGoodsType.FRAGILE]: 'Fragile',
    DEFAULT: 'Others',
  };

  return types[type] || types.DEFAULT;
};

export const listOfGoods = [
  {
    label: getActivityGoodsTypeLabel(SOActivityGoodsType.CLOTHES),
    value: SOActivityGoodsType.CLOTHES,
  },
  {
    label: getActivityGoodsTypeLabel(SOActivityGoodsType.DOCUMENT),
    value: SOActivityGoodsType.DOCUMENT,
  },
  {
    label: getActivityGoodsTypeLabel(SOActivityGoodsType.ELECTRONIC),
    value: SOActivityGoodsType.ELECTRONIC,
  },
  {
    label: getActivityGoodsTypeLabel(SOActivityGoodsType.FOOD),
    value: SOActivityGoodsType.FOOD,
  },
  {
    label: getActivityGoodsTypeLabel(SOActivityGoodsType.FRAGILE),
    value: SOActivityGoodsType.FRAGILE,
  },
  {
    label: getActivityGoodsTypeLabel(SOActivityGoodsType.OTHERS),
    value: SOActivityGoodsType.OTHERS,
  },
];

/**
 * get SO order status number from query string
 */
export function getSOOrderStatuses(orderStatuses: SOOrderStatus[]): SOStatus[] {
  return orderStatuses.map((status) => {
    const literal: Record<SOOrderStatus, SOStatus> = {
      [SOOrderStatus.IN_PROCESS]: SOStatus[status],
      [SOOrderStatus.RESERVED]: SOStatus[status],
      [SOOrderStatus.ASSIGNED]: SOStatus[status],
      [SOOrderStatus.DELETED]: SOStatus[status],
      [SOOrderStatus.DELIVERING]: SOStatus[status],
      [SOOrderStatus.DELIVERED]: SOStatus[status],
      [SOOrderStatus.TRANSITING]: SOStatus[status],
      [SOOrderStatus.IN_TRANSIT]: SOStatus[status],
    };

    return literal[status];
  });
}

/**
 * get SO unassigned list status number from query string
 */
export function getSOUnassignedStatuses(
  statuses: JOSOCandidateFilterFormStatus[],
): SOStatus[] {
  return statuses.map((status) => {
    const literal: Record<JOSOCandidateFilterFormStatus, SOStatus> = {
      [JOSOCandidateFilterFormStatus.IN_PROCESS]: SOStatus[status],
      [JOSOCandidateFilterFormStatus.IN_TRANSIT]: SOStatus[status],
    };

    return literal[status];
  });
}

export const isSOOnGoing = (status: SOStatus, joStatus?: JOStatus) => {
  if (!joStatus) return false;
  return (
    [JOStatus.DELIVERING].includes(joStatus) &&
    [SOStatus.DELIVERING, SOStatus.TRANSITING].includes(status)
  );
};

export const isSOCompleted = (status: SOStatus, joStatus?: JOStatus) => {
  if (!joStatus) return false;
  return (
    [JOStatus.DELIVERED, JOStatus.DELIVERING].includes(joStatus) &&
    [SOStatus.DELIVERED, SOStatus.IN_TRANSIT, SOStatus.TRANSITING].includes(
      status,
    )
  );
};

export function mapSOFilterStatusToLabel(status: SOStatus): string {
  switch (status) {
    case SOStatus.ASSIGNED:
      return 'Assigned';
    case SOStatus.TRANSITING:
      return 'Transiting';
    case SOStatus.IN_TRANSIT:
      return 'In Transit';
    case SOStatus.RESERVED:
      return 'Reserved';
    case SOStatus.DELETED:
      return 'Deleted';
    case SOStatus.DELIVERED:
      return 'Delivered';
    case SOStatus.DELIVERING:
      return 'Delivering';
    default:
      return 'In Process';
  }
}

export function getJODeliveryStatusLabel(status?: JobOrderDeliveryStatus) {
  switch (status) {
    case JobOrderDeliveryStatus.DRAFT:
      return 'Draft';
    case JobOrderDeliveryStatus.ON_GOING:
      return 'Ongoing';
    case JobOrderDeliveryStatus.COMPLETED:
      return 'Completed';
    default:
      return 'Assigned';
  }
}

/**
 * get SO payment status number from query string
 */
export function getSOPaymentStatuses(
  paymentStatuses: SOPaymentStatusLabel[],
): SOPaymentStatus[] {
  return paymentStatuses.map((status) => {
    const literal: Record<SOPaymentStatusLabel, SOPaymentStatus> = {
      [SOPaymentStatusLabel.UNPAID]: SOPaymentStatus[status],
      [SOPaymentStatusLabel.PAID]: SOPaymentStatus[status],
    };

    return literal[status];
  });
}

/**
 * map SO payment status to string label
 */
export function mapSOPaymentStatusToLabel(
  paymentStatus: SOPaymentStatus,
): string {
  const label: Record<SOPaymentStatus, string> = {
    [SOPaymentStatus.UNPAID]: 'Unpaid',
    [SOPaymentStatus.PAID]: 'Paid',
  };

  return label[paymentStatus];
}

/**
 * map SO payment statuses to translated labels
 */
export function mapFilterPaymentStatusesToLabel(
  paymentStatuses: SOPaymentStatusLabel[],
  translator: UseTranslator,
): string[] {
  const literal: Record<SOPaymentStatusLabel, string> = {
    [SOPaymentStatusLabel.UNPAID]: translator.translate('Unpaid'),
    [SOPaymentStatusLabel.PAID]: translator.translate('Paid'),
  };
  return paymentStatuses.map((paymentStatus) => literal[paymentStatus]);
}

/**
 * map SO order statuses to translated labels
 */
export function mapSOFilterOrderStatusesToLabels(
  orderStatuses: SOOrderStatus[],
  translator: UseTranslator,
): string[] {
  const literal: Record<SOOrderStatus, string> = {
    [SOOrderStatus.IN_PROCESS]: translator.translate('In Process'),
    [SOOrderStatus.RESERVED]: translator.translate('Reserved'),
    [SOOrderStatus.DELETED]: translator.translate('Deleted'),
    [SOOrderStatus.ASSIGNED]: translator.translate('Assigned'),
    [SOOrderStatus.DELIVERING]: translator.translate('Delivering'),
    [SOOrderStatus.DELIVERED]: translator.translate('Delivered'),
    [SOOrderStatus.IN_TRANSIT]: translator.translate('In Transit'),
    [SOOrderStatus.TRANSITING]: translator.translate('Transiting'),
  };
  return orderStatuses.map((status) => literal[status]);
}

export function mapSOFilterOrderByToLabel(orderBy: SOOrderBy): string {
  const orderByLabel: Record<SOOrderBy, string> = {
    [SOOrderBy.DATE_ASC]: 'Oldest Date',
    [SOOrderBy.DATE_DESC]: 'Newest Date',
    [SOOrderBy.UPDATED_AT_ASC]: 'Oldest Updates',
    [SOOrderBy.UPDATED_AT_DESC]: 'Newest Updates',
    [SOOrderBy.SHIPPER_NAME_ASC]: 'Shipper name A-Z',
    [SOOrderBy.SHIPPER_NAME_DESC]: 'Shipper name Z-A',
    [SOOrderBy.DELIVERY_COST_DESC]: 'Highest Delivery Cost',
    [SOOrderBy.RECEIVABLE_DESC]: 'Highest Remaining Receivables',
  };

  return orderByLabel[orderBy];
}

export function mapSOActivitiesToTableRowData(
  activities: SOListActivity[],
  translator: UseTranslator,
): MapSOActivitiesToTableRowDataOutput {
  const pickUps = activities.filter(
    (activity) => activity.type === SOActivityType.PICK_UP,
  );
  const dropOffs = activities.filter(
    (activity) => activity.type === SOActivityType.DROP_OFF,
  );

  let pickUpData: CustomTableRowWithPopoverProps;
  if (pickUps.length > 1) {
    pickUpData = {
      primaryLabel: `${pickUps.length} ${translator.translate(
        'Pickup Location',
      )}`,
    };
  } else if (pickUps.length === 1) {
    pickUpData = {
      primaryLabel: pickUps[0].location.name,
      secondaryLabel: pickUps[0].location.address,
    };
  } else {
    pickUpData = { primaryLabel: translator.translate('No Pickup Activity') };
  }

  let dropOffData: CustomTableRowWithPopoverProps;
  if (dropOffs.length > 1) {
    dropOffData = {
      primaryLabel: `${dropOffs.length} ${translator.translate(
        'Drop Off Locations',
      )}`,
    };
  } else if (dropOffs.length === 1) {
    dropOffData = {
      primaryLabel: dropOffs[0].location.name,
      secondaryLabel: dropOffs[0].location.address,
    };
  } else {
    dropOffData = {
      primaryLabel: translator.translate('No Drop-Off Activity'),
    };
  }

  return {
    pickUpData,
    dropOffData,
  };
}

export const getActivityEventTypeLabel = ({
  type,
  locationName = '',
  noteTitle,
  translator,
}: {
  type: ShipperOrderActivityEventType;
  locationName?: string;
  noteTitle: string;
  translator: UseTranslator;
}) => {
  const types: Record<ShipperOrderActivityEventType | 'DEFAULT', string> = {
    [ShipperOrderActivityEventType.GEOFENCE_ENTER]: `${translator.translate(
      'Arrived at',
    )} ${locationName}`,
    [ShipperOrderActivityEventType.GEOFENCE_EXIT]: `${translator.translate(
      'Departed from',
    )} ${locationName}`,
    [ShipperOrderActivityEventType.ACTIVITY_COMPLETED]: translator.translate(
      'Finished the activity',
    ),
    [ShipperOrderActivityEventType.ACTIVITY_STARTED]: translator.translate(
      'Started the activity',
    ),
    [ShipperOrderActivityEventType.PROOF_OF_ACTIVITY_ADDED]:
      translator.translate('Uploaded the proof of activity'),
    [ShipperOrderActivityEventType.NOTES_ADDED]: noteTitle,
    DEFAULT: translator.translate('Unknown activity'),
  };

  return types[type] || types.DEFAULT;
};

/**
 * @param  {UseTranslator} translator
 * @returns OrderByValue[] ~ SO
 *
 * @summary get SO filterOrderBy values
 */
export function getFilterOrderByValues(
  translator: UseTranslator,
): OrderByValue[] {
  return [
    {
      label: translator.translate('Newest Date'),
      value: SOOrderBy.DATE_DESC,
    },
    {
      label: translator.translate('Oldest Date'),
      value: SOOrderBy.DATE_ASC,
    },
    {
      label: translator.translate('Newest Updates'),
      value: SOOrderBy.UPDATED_AT_DESC,
    },
    {
      label: translator.translate('Oldest Updates'),
      value: SOOrderBy.UPDATED_AT_ASC,
    },
    {
      label: translator.translate('Shipper name A-Z'),
      value: SOOrderBy.SHIPPER_NAME_ASC,
    },
    {
      label: translator.translate('Shipper name Z-A'),
      value: SOOrderBy.SHIPPER_NAME_DESC,
    },
    {
      label: translator.translate('Highest Delivery Cost'),
      value: SOOrderBy.DELIVERY_COST_DESC,
    },
    {
      label: translator.translate('Highest Remaining Receivables'),
      value: SOOrderBy.RECEIVABLE_DESC,
    },
  ];
}

/**
 * @param  {UseTranslator} translator
 * @returns OrderStatusValue[] ~ SO
 *
 * @summary get SO filterOrderStatus values
 */
export function getFilterOrderStatusValues(
  translator: UseTranslator,
): OrderStatusValue[] {
  return [
    {
      label: translator.translate('In Process'),
      value: SOOrderStatus.IN_PROCESS,
    },
    {
      label: translator.translate('Assigned'),
      value: SOOrderStatus.ASSIGNED,
    },
    {
      label: translator.translate('Delivering'),
      value: SOOrderStatus.DELIVERING,
    },
    {
      label: translator.translate('Reserved'),
      value: SOOrderStatus.RESERVED,
    },
    {
      label: translator.translate('Delivered'),
      value: SOOrderStatus.DELIVERED,
    },
    {
      label: translator.translate('In Transit'),
      value: SOOrderStatus.IN_TRANSIT,
    },
    {
      label: translator.translate('Transiting'),
      value: SOOrderStatus.TRANSITING,
    },
    {
      label: translator.translate('Deleted'),
      value: SOOrderStatus.DELETED,
    },
  ];
}

/**
 * @param  {UseTranslator} translator
 * @returns OrderStatusValue[] ~ SO
 *
 * @summary get SO filterOrderStatus values
 */
export function getFilterPaymentStatusValues(
  translator: UseTranslator,
): PaymentStatusValue[] {
  return [
    {
      label: translator.translate('Paid'),
      value: SOPaymentStatusLabel.PAID,
    },
    {
      label: translator.translate('Unpaid'),
      value: SOPaymentStatusLabel.UNPAID,
    },
  ];
}

/**
 * Convert activity type to a label
 * @param type
 * @returns
 */
export const activityTypeToLabel = (type?: SOActivityType) => {
  const obj: Record<string, string> = {
    [SOActivityType.DROP_OFF]: 'Drop Off',
    [SOActivityType.PICK_UP]: 'Pickup',
    [SOActivityType.STAND_BY]: 'Stand By',
    DEFAULT: 'Stand By',
  };
  if (!type) return obj.DEFAULT;
  return obj[type];
};

/**
 * default toast error with `react-toastify`
 * @param message Error message
 * @returns void
 */
export const toastError = (message: string) => {
  toast(
    ({ closeToast }) => (
      <ErrorContainer>
        <ErrorContent>{message}</ErrorContent>

        <ErrorCloseButton
          onClick={(ev) => {
            ev.stopPropagation();
            closeToast?.();
          }}
        >
          <Icon.Close height={28} width={28} />
        </ErrorCloseButton>
      </ErrorContainer>
    ),
    {
      autoClose: 3000,
      closeButton: false,
      style: {
        padding: 0,
        borderRadius: '6px',
        minHeight: 'auto',
      },
      bodyStyle: {
        margin: 0,
        padding: 0,
      },
    },
  );
};

export const getNoteTitleFromActivity = (
  translator: UseTranslator,
  eventTime: number,
  activityNotes?: SOActivityDetailNote[],
) => {
  if (!activityNotes) return '';
  const notesIndex =
    activityNotes
      .map((_note, _noteIdx) => ({
        ..._note,
        number: _noteIdx + 1,
      }))
      .find((_note) => _note.createdAt === eventTime)?.number ?? '';
  return `${translator.translate('Note')} #${notesIndex}`;
};

export const getNoteDescriptionFromActivity = (
  eventTime: number,
  activityNotes?: SOActivityDetailNote[],
) => {
  if (!activityNotes) return '';

  return (
    activityNotes
      .find((_note) => _note.createdAt === eventTime)
      ?.notes.trim() ?? ''
  );
};

/**
 * get shipper order navigation state params, for navigation consideration
 */
export const getSONavigationState = (
  navigateParams: ShipperOrderCreateNavigateParams,
) => {
  const options =
    navigateParams?.originPath && navigateParams?.joState
      ? { state: { formValues: navigateParams?.joState } }
      : undefined;

  const isOriginSORoute =
    !navigateParams?.originPath ||
    navigateParams?.originPath === shipperOrderRoute.path;

  const originSOCreateRoute =
    navigateParams?.originPath === createShipperOrderRoute.path
      ? shipperOrderRoute.path
      : navigateParams?.originPath;

  const originPath = navigateParams?.originPath
    ? originSOCreateRoute
    : shipperOrderRoute.path;

  return { isOriginSORoute, originPath, options } as const;
};

/**
 * Get Shipper Order Custom Error Message
 */
export const getSOCustomErrorMessage = (
  so: DeleteBulkShipperOrderEntity,
  errorCode?: string,
) => {
  if (so.status === SOStatus.DELETED) return 'Shipper Order has been deleted';
  if (so.status === SOStatus.RESERVED)
    return 'Shipper Order need to be removed from related Job Order';
  if (
    [
      SOStatus.DELIVERED,
      SOStatus.ASSIGNED,
      SOStatus.IN_TRANSIT,
      SOStatus.DELIVERING,
      SOStatus.DELIVERED,
      SOStatus.TRANSITING,
    ].includes(so.status)
  )
    return 'Shipper Order is on delivery';
  return errorCodeToLabel(errorCode);
};

/**
 * check bulk shipper order create excel data validity
 */
export const checkShipperOrderCreateExcelDataValidity = (
  columns: unknown[],
  data: unknown[],
) => {
  const commonErrorLabel =
    'has invalid format. Please use provided XLS template and re-upload';
  const exceeded100ErrorLabel =
    'exceeded the maximum data allowed. Please reduce the data amount to a maximum of 100 rows';

  // check at least 1 data / data columns to be exactly 19
  if (!data.length || columns.length !== 22) return commonErrorLabel;
  // check max 100 data
  if (data.length > 100) return exceeded100ErrorLabel;

  return '' as const;
};

export const mapActivityTypeFromExcelToEnum = (type: string) => {
  if (!type) return SOActivityType.STAND_BY;

  const obj: Record<string, SOActivityType> = {
    Pickup: SOActivityType.PICK_UP,
    Dropoff: SOActivityType.DROP_OFF,
    Standby: SOActivityType.STAND_BY,
  };
  return obj[type] || SOActivityType.STAND_BY;
};

export const mapGoodsTypeFromExcelToEnum = (type?: string) => {
  if (!type) return SOActivityGoodsType.OTHERS;

  const obj: Record<string, SOActivityGoodsType> = {
    Pakaian: SOActivityGoodsType.CLOTHES,
    Dokumen: SOActivityGoodsType.DOCUMENT,
    Elektronik: SOActivityGoodsType.ELECTRONIC,
    Makanan: SOActivityGoodsType.FOOD,
    'Barang Pecah Belah': SOActivityGoodsType.FRAGILE,
    Lainnya: SOActivityGoodsType.OTHERS,
  };
  return obj[type] || SOActivityGoodsType.OTHERS;
};
