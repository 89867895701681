export enum ErrorCodes {
  ACTIVITY_TEMPLATE_NAME_TAKEN = 'activity-template/name-taken',
  AUTH_TOKEN_ALREADY_CONSUMED = 'auth/token-already-consumed',
  AUTH_INVALID_CREDENTIALS = 'auth/invalid-credentials',
  AUTH_TOKEN_EXPIRED = 'auth/token-expired',
  AUTH_TEST_NO_ARGUMENT = 'auth/test-no-argument',
  AUTH_SESSION_NOT_FOUND = 'auth/session-not-found',
  DRIVER_ALREADY_ASSIGNED_TO_DRIVING_CONTEST = 'driver/already-assigned-to-driving-contest',
  DRIVER_DRIVING_CONTEST_NOT_YET_ASSIGNED = 'driver/driving-contest-not-yet-assigned',
  DRIVER_ALREADY_ACTIVE = 'driver/already-active',
  DRIVER_ALREADY_DEACTIVATED = 'driver/already-deactivated',
  DRIVER_ALREADY_ON_DUTY = 'driver/already-on-duty',
  DRIVER_HAS_ACTIVE_ASSIGNMENT = 'driver/has-active-assignment',
  DRIVER_HAS_FUTURE_ASSIGNMENT = 'driver/has-future-assignment',
  DRIVER_ALREADY_ASSIGNED = 'driver/already-assigned-to-driving-contest',
  DRIVER_NOT_FOUND = 'driver/not-found',
  DRIVER_PHONE_NUMBER_TAKEN = 'driver/phone-number-taken',
  DRIVER_VEHICLE_ALREADY_ASSIGNED = 'driver/vehicle-already-assigned',
  DRIVER_INVALID_PIN_FORMAT = 'driver/invalid-pin-format',
  DRIVER_TIMESHEET_OVERLAPPING = 'driver/timesheet-overlapping',
  DRIVER_TIMESHEET_DETAIL_NOT_FOUND = 'driver/timesheet-detail-not-found',
  HO_REFERENCE_NUMBER_TAKEN = 'hauling-order/reference-number-taken',
  HO_NUMBER_TAKEN = 'hauling-order/number-taken',
  JO_NUMBER_TAKEN = 'job-order/number-taken',
  JO_NUMBER_FORMAT_NOT_ALLOWED = 'job-order/number-format-not-allowed',
  JO_NOT_FOUND = 'job-order/not-found',
  JO_SO_NOT_AVAILABLE = 'job-order/shipper-order-is-not-available',
  JO_ALREADY_DELIVERING = 'job-order/is-already-delivering',
  JO_DRIVER_MISS_MATCH = 'job-order/driver-miss-match',
  JO_OPERATION_NOT_PERMITTED = 'job-order/operation-not-permitted',
  JO_DELIVERY_ALREADY_COMPLETED = 'job-order/delivery-already-completed',
  JO_DELIVERY_ACTIVITY_ALREADY_COMPLETED = 'job-order/delivery-activity-already-completed',
  JO_EMPTY = 'job-order/empty',
  JO_SO_NOT_FOUND = 'job-order/shipper-order-not-found',
  JO_DRIVER_NOT_FOUND = 'job-order/driver-not-found',
  JO_VEHICLE_NOT_FOUND = 'job-order/vehicle-not-found',
  JO_EXPENSE_OPERATION_NOT_PERMITTED = 'job-order/expense-operation-not-permitted',
  SO_EMPTY = 'shipper-order/empty',
  HO_EMPTY = 'hauling-order/empty',
  DRIVER_EMPTY = 'driver/empty',
  DRIVER_LAST_POSITION_UNKNOWN = 'driver/last-position-unknown',
  SHIPPER_EMPTY = 'shipper/empty',
  LOCATION_EMPTY = 'location/empty',
  HO_ORDER_NUMBER_TAKEN = 'hauling-order/order-number-taken',
  HO_NUMBER_FORMAT_NOT_ALLOWED = 'hauling-order/number-format-not-allowed',
  HO_SHIPPER_LOCATION_MISS_MATCH = 'hauling-order/shipper-location-miss-match',
  HO_NOT_YET_ASSIGNED = 'hauling-order/not-yet-assigned',
  HO_ALREADY_ASSIGNED = 'hauling-order/already-assigned',
  HO_ALREADY_STARTED = 'hauling-order/already-started',
  HO_ALREADY_DELIVERED = 'hauling-order/already-delivered',
  HJO_ALREADY_DELIVERED = 'hauling-order/delivery-assignment-already-delivered',
  LOCATION_NAME_TAKEN = 'location/name-taken',
  LOCATION_NOT_FOUND = 'location/not-found',
  LOCATION_EXISTS_IN_ACTIVE_SO = 'location/exists-in-active-shipper-order',
  LOCATION_EXISTS_IN_ACTIVE_HO = 'location/exists-in-active-hauling-order',
  LOCATION_EXISTS_IN_ACTIVITY_TEMPLATE = 'location/exists-in-activity-template',
  ORGANIZATION_NOT_FOUND = 'organization/not-found',
  REQUEST_INVALID_ARGUMENT = 'request/invalid-arguments',
  REPORT_SHIPPER_ORDER_DATA_NOT_EXISTS = 'report/shipper-order-data-not-exists',
  REPORT_JOB_ORDER_DATA_NOT_EXISTS = 'report/job-order-data-not-exists',
  REPORT_SHIPPER_REVENUE_DATA_NOT_EXISTS = 'report/shipper-revenue-data-not-exists',
  SCHEDULE_NOT_FOUND = 'driver/vehicle-assignment-not-found',
  SHIPPER_ALREADY_DEACTIVATED = 'shipper/already-deactivated',
  SHIPPER_NAME_TAKEN = 'shipper/name-taken',
  SHIPPER_NOT_FOUND = 'shipper/not-found',
  SHIPPER_PHONE_NUMBER_TAKEN = 'shipper/phone-number-taken',
  SHIPPER_TOTAL_REVENUE_NOT_CALCULATED = 'shipper/total-shipper-revenue-not-calculated',
  SO_GOODS_DEADLINE_EXCEEDED = 'shipper-order/update-activity-goods-deadline-exceeded',
  SO_GOODS_LENGTH_ERROR = 'shipper-order/activity-goods-length-miss-match',
  SO_GOODS_NOT_FOUND = 'shipper-order/activity-goods-not-found',
  SO_GOODS_TYPE_ERROR = 'shipper-order/activity-goods-type-must-drop-off',
  SO_ORDER_NUMBER_TAKEN = 'shipper-order/order-number-taken',
  SO_NUMBER_TAKEN = 'shipper-order/number-taken',
  SO_TRACKING_CODE_TAKEN = 'shipper-order/tracking-code-taken',
  SO_ALREADY_PAID = 'shipper-order/payment-status-already-paid',
  SO_OPERATION_NOT_PERMITTED = 'shipper-order/operation-not-permitted',
  SO_REFERENCE_NUMBER_TAKEN = 'shipper-order/reference-number-taken',
  SO_PAYMENT_STATUS_ALREADY_PAID = 'shipper-order/payment-status-already-paid',
  SO_PAYMENT_AMOUNT_LIMIT_EXCEEDED = 'shipper-order/payment-amount-limit-exceeded',
  SO_PAYMENT_NOT_FOUND = 'shipper-order/payment-not-found',
  SO_ALREADY_DELETED = 'shipper-order/already-deleted',
  SO_ALREADY_RESERVED = 'shipper-order/already-reserved',
  SO_ALREADY_ASSIGNED = 'shipper-order/already-assigned',
  SO_ALREADY_DELIVERED = 'shipper-order/already-delivered',
  SO_ALREADY_IN_TRANSIT = 'shipper-order/already-in-transit',
  SO_ALREADY_STARTED = 'shipper-order/already-started',
  SO_ALREADY_TRANSITING = 'shipper-order/already-transiting',
  SSO_CHECK_AUTH_FAILED = 'sso/check-auth-failed',
  SSO_REFRESH_TOKEN_FAILED = 'sso/refresh-token-failed',
  SSO_VERIFY_AUTH_FAILED = 'sso/verify-auth-failed',
  SSO_FORBIDDEN_REQUEST = 'sso/forbidden-request',
  SSO_VISA_CREDENTIAL_FAILED = 'sso/visa-credential-failed',
  SO_NOT_FOUND = 'shipper-order/not-found',
  VEHICLE_LAST_POSITION_UNKNOWN = 'vehicle/last-position-unknown',
  ORGANIZATION_IN_RESETTING = 'organization/in-resetting',
  ERROR_CODE_NOT_AVAILABLE = 'system/error-code-not-available',
}

export enum ErrorRule {
  STRING_ALPHANUM = 'string.alphanum',
  STRING_BASE = 'string.base',
  STRING_BASE64 = 'string.base64',
  STRING_CREDITCARD = 'string.creditCard',
  STRING_DATAURI = 'string.dataUri',
  STRING_DOMAIN = 'string.domain',
  STRING_EMAIL = 'string.email',
  STRING_EMPTY = 'string.empty',
  STRING_GUID = 'string.guid',
  STRING_HEX = 'string.hex',
  STRING_HEXALIGN = 'string.hexAlign',
  STRING_HOSTNAME = 'string.hostname',
  STRING_IP = 'string.ip',
  STRING_IPVERSION = 'string.ipVersion',
  STRING_ISODATE = 'string.isoDate',
  STRING_ISODURATION = 'string.isoDuration',
  STRING_LENGTH = 'string.length',
  STRING_LOWERCASE = 'string.lowercase',
  STRING_MAX = 'string.max',
  STRING_MIN = 'string.min',
  STRING_NORMALIZE = 'string.normalize',
  STRING_TOKEN = 'string.token',
  STRING_PATTERNBASE = 'string.pattern.base',
  STRING_PATTERNNAME = 'string.pattern.name',
  STRING_PATTERNINVERTBASE = 'string.pattern.invert.base',
  STRING_PATTERNINVERTNAME = 'string.pattern.invert.name',
  STRING_TRIM = 'string.trim',
  STRING_URI = 'string.uri',
  STRING_URICUSTOMSCHEME = 'string.uriCustomScheme',
  STRING_URIRELATIVEONLY = 'string.uriRelativeOnly',
  STRING_UPPERCASE = 'string.uppercase',
  NUMBER_BASE = 'number.base',
  NUMBER_GREATER = 'number.greater',
  NUMBER_INFINITY = 'number.infinity',
  NUMBER_INTEGER = 'number.integer',
  NUMBER_LESS = 'number.less',
  NUMBER_MAX = 'number.max',
  NUMBER_MIN = 'number.min',
  NUMBER_MULTIPLE = 'number.multiple',
  NUMBER_NEGATIVE = 'number.negative',
  NUMBER_PORT = 'number.port',
  NUMBER_POSITIVE = 'number.positive',
  NUMBER_PRECISION = 'number.precision',
  NUMBER_UNSAFE = 'number.unsafe',
  DATE_MIN = 'date.min',
  DATE_BASE = 'date.base',
  ALTERNATIVES_MATCH = 'alternatives.match',
  ALTERNATIVES_TYPES = 'alternatives.types',
  ANY_REQUIRED = 'any.required',
}
