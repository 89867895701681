import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import getUnixTime from 'date-fns/getUnixTime';
import startOfToday from 'date-fns/startOfToday';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store/store.app';
import {
  BulkActionEntity,
  BulkActionStatusEnum,
  ErrorCodes,
} from '../../../constant';
import useOrganizationOrientation from '../../../hook/organization/useOrganizationOrientation.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { logEvent } from '../../../service/analytic/analytic.service';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { PostJobOrderCreateBulkParamsData } from '../../../service/endpoint/jobOrder/jobOrder.endpoint';
import { navigationParamAction } from '../../../store/param.store';
import { errorCodeToLabel } from '../../../util/error.util';
import { jobOrderRoute } from '../../JobOrder/JobOrder.route';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import { JOCreateBulk } from '../joCreateBulk.route';

// #region TYPES
const UNDEFINED_JOB_ORDER_NUMBER = 'undefined-job-order-number';
export type createBulkJOEntity =
  BulkActionEntity<PostJobOrderCreateBulkParamsData> & {
    failedSoNumber?: string[];
  };
export type UseJOCreateBulkLogic = ReturnType<typeof useJOCreateBulkLogic>;
export type MappedJOCreateBulk = Record<
  string,
  {
    driverName: string;
    vehicleName: string;
    travelExpense?: number;
    soNumbers: string[];
  }
>;
// #endregion

function mapStoreToState(jobOrders: JOCreateBulk[]): createBulkJOEntity[] {
  const joMap: MappedJOCreateBulk = jobOrders.reduce((map, item) => {
    const joNumber = item['Nomor JO*']?.toString();

    if (!map[joNumber]) {
      map[joNumber] = {
        driverName: item['Driver*']?.toString(),
        vehicleName: item['Kendaraan*']?.toString(),
        soNumbers: [],
        travelExpense: item['Uang Jalan'],
      };
    }

    if (map[joNumber] && !!item['Nomor SO*']) {
      map[joNumber].soNumbers.push(item['Nomor SO*']?.toString());
    }

    return map;
  }, {} as MappedJOCreateBulk);

  const joDate = getUnixTime(startOfToday());

  return Object.entries(joMap).map(
    ([joNumber, { driverName, vehicleName, soNumbers, travelExpense }]) => ({
      joNumber: joNumber === 'undefined' ? undefined : joNumber,
      joDate,
      driverName,
      vehicleName,
      travelExpense,
      soNumbers,
      _actionDetail: '',
      _actionStatus: BulkActionStatusEnum.WAITING,
    }),
  );
}

export default function useJOCreateBulkLogic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslator();
  const { handleVerifyAuth } = useVerifyAuth();
  const { handleFetchOrganizationData } = useOrganizationOrientation();

  // #region VALUES
  const [showStatus, setShowStatus] = useState(false);

  const [truncatedFilename, setTruncatedFilename] = useState('');

  const { filename, jobOrders: jobOrderStore } = useSelector(
    (state: RootState) => {
      return (
        state.navigationParam.joCreateBulkParams ?? {
          filename: '',
          jobOrders: [],
        }
      );
    },
  );

  const [jobOrdersWithStatusDetail, setJobOrdersWithStatusDetail] = useState<
    createBulkJOEntity[]
  >(mapStoreToState(jobOrderStore));
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);
  const [showDoneBtn, setShowDoneBtn] = useState(false);
  // to disable submit button when mutations is still ongoing
  const [mutationsIsLoading, setMutationsIsLoading] = useState(false);
  const [doCreateJobOrder] = api.useCreateJobOrdersBulkMutation();
  // #endregion

  // #region HANDLERS
  const handleAddBulk = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const promises = jobOrdersWithStatusDetail.map((jobOrder) =>
      doCreateJobOrder({
        jobOrders: [
          {
            ...(jobOrder.travelExpense && {
              travelExpense: jobOrder.travelExpense,
            }),
            joNumber: jobOrder.joNumber,
            joDate: jobOrder.joDate,
            driverName: jobOrder.driverName,
            vehicleName: jobOrder.vehicleName,
            soNumbers: jobOrder.soNumbers,
          },
        ],
      }).unwrap(),
    );

    // track loading state
    setMutationsIsLoading(true);

    // NOTE: backend said this endpoint is safe to the parallel mutation
    await Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          // on rejected
          if (result.status === 'rejected') {
            const errorQuery = (result.reason as FetchBaseQueryError)
              ?.data as ApiErrorResponse;
            const errorCode = errorQuery?.error?.code;
            const errorDetails =
              errorQuery?.error?.details ?? translate('Invalid arguments');

            setJobOrdersWithStatusDetail((_jobOrders) =>
              _jobOrders.map((_jobOrder) => {
                const currentJobOrderNumber =
                  _jobOrder.joNumber ?? UNDEFINED_JOB_ORDER_NUMBER;
                const responseJobOrderNumber =
                  jobOrdersWithStatusDetail[idx].joNumber ??
                  UNDEFINED_JOB_ORDER_NUMBER;
                return currentJobOrderNumber === responseJobOrderNumber
                  ? {
                      ..._jobOrder,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail:
                        errorCode === ErrorCodes.REQUEST_INVALID_ARGUMENT
                          ? errorDetails
                          : errorCodeToLabel(errorCode),
                    }
                  : _jobOrder;
              }),
            );
            continue;
          }

          if (result.value.result.errors.length > 0) {
            const errorQuery = result.value.result.errors[0];

            const errorCode = errorQuery.code;
            const isSoNotFound = errorCode === ErrorCodes.JO_SO_NOT_FOUND;

            setJobOrdersWithStatusDetail((_jobOrders) =>
              _jobOrders.map((_jobOrder) => {
                const currentJobOrderNumber =
                  _jobOrder.joNumber ?? UNDEFINED_JOB_ORDER_NUMBER;
                const responseJobOrderNumber =
                  jobOrdersWithStatusDetail[idx].joNumber ??
                  UNDEFINED_JOB_ORDER_NUMBER;
                return currentJobOrderNumber === responseJobOrderNumber
                  ? {
                      ..._jobOrder,
                      _actionStatus: BulkActionStatusEnum.FAILED,
                      _actionDetail: isSoNotFound
                        ? `${
                            errorQuery?.data?.soNumber?.length ?? 1
                          } ${translate('Shipper Order not found.')}`
                        : errorCodeToLabel(errorCode),
                      failedSoNumber:
                        isSoNotFound && !!errorQuery?.data?.soNumber
                          ? errorQuery?.data?.soNumber
                          : undefined,
                    }
                  : _jobOrder;
              }),
            );
            continue;
          }

          // on fulfilled
          setJobOrdersWithStatusDetail((_jobOrders) =>
            _jobOrders.map((_jobOrder) =>
              _jobOrder.joNumber === jobOrdersWithStatusDetail[idx].joNumber
                ? {
                    ..._jobOrder,
                    _actionStatus: BulkActionStatusEnum.SUCCEED,
                    _actionDetail: 'Job Order registered successfully',
                  }
                : _jobOrder,
            ),
          );

          logEvent('JobOrder:AddBulk');
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
        setHasUnsavedChanges(false);
      });
  }, [
    doCreateJobOrder,
    handleVerifyAuth,
    translate,
    jobOrdersWithStatusDetail,
  ]);

  const handleDone = useCallback(() => {
    // clear store
    dispatch(navigationParamAction.clearJOCreateBulkParams());
    navigate(jobOrderRoute.path);
    handleFetchOrganizationData();
  }, [dispatch, handleFetchOrganizationData, navigate]);
  // #endregion

  // sync `jobOrdersStore` with `jobOrdersWithStatusDetail`
  useEffect(() => {
    if (jobOrderStore.length > 0)
      setJobOrdersWithStatusDetail(mapStoreToState(jobOrderStore));
  }, [jobOrderStore]);

  // sync redux store with state
  useEffect(() => {
    if (filename.length < 30) {
      setTruncatedFilename(filename);
      return;
    }
    const [name, format] = filename.split('.');
    const truncatedName = name.slice(0, 25);
    setTruncatedFilename(`${truncatedName}...${format}`);
  }, [filename]);

  return {
    hasUnsavedChanges,
    filename,
    truncatedFilename,
    jobOrdersWithStatusDetail,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    handleAddBulk,
    handleDone,
  };
}
