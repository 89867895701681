import React from 'react';
import tw, { theme } from 'twin.macro';
import { SOActivityListData, SOActivityType } from '../../../constant';
import soActivityColumns from '../../../constant/Goods.constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { ShipperOrderInfo } from '../../../model/ShipperOrder.model';
import { numberFormatter } from '../../../util/formatter.util';
import { getGoodsTableTotal } from '../../../util/shipperOrderCreate.util';
import {
  hasGoodsOut,
  isGoodsEditable,
} from '../../../util/shipperOrderDetail.util';
import { CardContainer, Icon, Text } from '../../atom';
import {
  CardContent,
  CardHeader,
  CollapsiblePanel,
  SOActivityTable,
} from '../../molecule';

const Container = tw.div`w-[720px] mx-auto pb-5`;
const HeaderContentItemContainer = tw.div`flex flex-row gap-8 py-2 border-b-2 border-b-beige-lines animate-slide-in-left last-of-type:border-0`;

type Props = {
  title: string;
  soInfo?: ShipperOrderInfo;
  activities?: SOActivityListData[];
  translator: UseTranslator;
  onChangeActivityIndex: (activity: number, isGoodsOut?: boolean) => void;
};

export default function SODActivitySection({
  title,
  activities,
  translator,
  soInfo,
  onChangeActivityIndex,
}: Props) {
  const tooltip = (
    <div tw="flex-wrap whitespace-pre-wrap">
      {translator.translate(
        'You can update and edit the goods delivered by driver up to',
      )}{' '}
      <span tw="font-bold">{translator.translate('72 hours')}</span>{' '}
      {translator.translate('after the Shipper Order status changes to')}{' '}
      <span tw="font-bold">
        &quot;{translator.translate('Delivered')}&quot;
      </span>
    </div>
  );

  if (!soInfo) {
    return (
      <Container>
        <CardContainer>
          <CardHeader containerStyle={tw`h-14`} title={title} />
          <CardContent tw="space-y-5">
            {translator.translate('Information is not yet available')}
          </CardContent>
        </CardContainer>
      </Container>
    );
  }

  return (
    <Container>
      <CardContainer>
        <CardHeader containerStyle={tw`h-14`} title={title} />
        <CardContent tw="space-y-5">
          {activities?.map((activity, i) => (
            <CollapsiblePanel
              title={`${translator.translate('Activity')} ${i + 1}`}
              key={activity.id}
            >
              {activity.header.map((info) => (
                <HeaderContentItemContainer key={info.id}>
                  <Text.Label tw="w-28 min-w-[7rem] text-grey-three whitespace-normal">
                    {translator.translate(info.label)}
                  </Text.Label>
                  <Text.BodyOne tw="truncate">{info.value}</Text.BodyOne>
                </HeaderContentItemContainer>
              ))}
              {activity.type !== SOActivityType.STAND_BY && (
                <>
                  <div tw="mt-2.5" />
                  <CollapsiblePanel
                    title={translator.translate('Goods Received by Driver')}
                    action={
                      isGoodsEditable(activity, soInfo)
                        ? {
                            click: () => onChangeActivityIndex(i),
                            icon: <Icon.EditOutlined />,
                            text: translator.translate('Update'),
                          }
                        : undefined
                    }
                    information={
                      isGoodsEditable(activity, soInfo) ? tooltip : undefined
                    }
                  >
                    <SOActivityTable
                      columns={soActivityColumns.map((v) => ({
                        ...v,
                        label: translator.translate(v.label),
                      }))}
                      rows={activity.goods}
                      total={{
                        label: translator.translate('Total'),
                        volume: numberFormatter(
                          getGoodsTableTotal('volume', activity.goods),
                        ),
                        weight: numberFormatter(
                          getGoodsTableTotal('weight', activity.goods),
                        ),
                      }}
                      translator={translator}
                    />
                  </CollapsiblePanel>
                  {hasGoodsOut(activity) && (
                    <>
                      <div tw="mt-2.5" />
                      <CollapsiblePanel
                        title={translator.translate(
                          'Goods Delivered by Driver',
                        )}
                        action={
                          isGoodsEditable(activity, soInfo, false)
                            ? {
                                click: () => onChangeActivityIndex(i, true),
                                icon: <Icon.EditOutlined />,
                                text: translator.translate('Edit'),
                              }
                            : undefined
                        }
                        containerStyle={tw`border-cyan-light`}
                        headerStyle={tw`bg-status-success-light border-b-cyan-light`}
                        information={
                          isGoodsEditable(activity, soInfo, false)
                            ? tooltip
                            : undefined
                        }
                      >
                        <SOActivityTable
                          columns={soActivityColumns.map((v) => ({
                            ...v,
                            label: translator.translate(v.label),
                          }))}
                          rows={activity.goodsOut}
                          total={{
                            label: translator.translate('Total'),
                            volume: numberFormatter(
                              getGoodsTableTotal('volume', activity.goodsOut),
                            ),
                            weight: numberFormatter(
                              getGoodsTableTotal('weight', activity.goodsOut),
                            ),
                          }}
                          borderColor={theme`colors.cyan.light`}
                          translator={translator}
                        />
                      </CollapsiblePanel>
                    </>
                  )}
                </>
              )}
            </CollapsiblePanel>
          ))}
        </CardContent>
      </CardContainer>
    </Container>
  );
}
