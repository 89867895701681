import envConfig from '../config/env/env.config';

const DriverContestRegisterFirstA = `${envConfig.assetsUrl}DriverContestRegister-1-1.png`;
const DriverContestRegisterFirstB = `${envConfig.assetsUrl}DriverContestRegister-1-2.png`;
const DriverContestRegisterSecond = `${envConfig.assetsUrl}DriverContestRegister-2.png`;
const DriverContestRegisterThird = `${envConfig.assetsUrl}DriverContestRegister-3.png`;
const DriverContestRegisterFourth = `${envConfig.assetsUrl}DriverContestRegister-4.png`;
const DriverContestRegisterFifth = `${envConfig.assetsUrl}DriverContestRegister-5.png`;
const DriverContestRegisterSixth = `${envConfig.assetsUrl}DriverContestRegister-6.png`;

const DriverContestRemovalFirstA = `${envConfig.assetsUrl}DriverContestRemoval-1-1.png`;
const DriverContestRemovalFirstB = `${envConfig.assetsUrl}DriverContestRemoval-1-2.png`;
const DriverContestRemovalSecond = `${envConfig.assetsUrl}DriverContestRemoval-2.png`;
const DriverContestRemovalThird = `${envConfig.assetsUrl}DriverContestRemoval-3.png`;
const DriverContestRemovalFourth = `${envConfig.assetsUrl}DriverContestRemoval-4.png`;
const DriverContestRemovalFifth = `${envConfig.assetsUrl}DriverContestRemoval-5.png`;

const DriverAddImportDriverFirst = `${envConfig.assetsUrl}AddDriverImport-1.png`;
const DriverAddImportDriverSecond = `${envConfig.assetsUrl}AddDriverImport-2.png`;
const DriverAddImportDriverThird = `${envConfig.assetsUrl}AddDriverImport-3.png`;
const DriverAddImportDriverFourth = `${envConfig.assetsUrl}AddDriverImport-4.png`;
const DriverAddImportDriverFifth = `${envConfig.assetsUrl}AddDriverImport-5.png`;
const DriverAddImportDriverSixth = `${envConfig.assetsUrl}AddDriverImport-6.png`;

const DriverAddInputManualFirstA = `${envConfig.assetsUrl}AddDriverInputManual-1-1.png`;
const DriverAddInputManualFirstB = `${envConfig.assetsUrl}AddDriverInputManual-1-2.png`;
const DriverAddInputManualSecond = `${envConfig.assetsUrl}AddDriverInputManual-2.png`;
const DriverAddInputManualThird = `${envConfig.assetsUrl}AddDriverInputManual-3.png`;
const DriverAddInputManualFourthA = `${envConfig.assetsUrl}AddDriverInputManual-4-1.png`;
const DriverAddInputManualFourthB = `${envConfig.assetsUrl}AddDriverInputManual-4-2.png`;

const AddScheduleFirst = `${envConfig.assetsUrl}AddSchedule-1.png`;
const AddScheduleSecond = `${envConfig.assetsUrl}AddSchedule-2.png`;

const NonActiveDriverFirst = `${envConfig.assetsUrl}NonActiveDriver-1.png`;
const NonActiveDriverSecond = `${envConfig.assetsUrl}NonActiveDriver-2.png`;
const NonActiveDriverThird = `${envConfig.assetsUrl}NonActiveDriver-3.png`;
const NonActiveDriverFourth = `${envConfig.assetsUrl}NonActiveDriver-4.png`;

const EditDriverFirst = `${envConfig.assetsUrl}EditDriver-1.png`;
const EditDriverSecond = `${envConfig.assetsUrl}EditDriver-2.png`;
const EditDriverThird = `${envConfig.assetsUrl}EditDriver-3.png`;
const EditDriverFourth = `${envConfig.assetsUrl}EditDriver-4.png`;

const RemoveScheduleFirst = `${envConfig.assetsUrl}RemoveSchedule-1.png`;
const RemoveScheduleSecond = `${envConfig.assetsUrl}RemoveSchedule-2.png`;
const RemoveScheduleThird = `${envConfig.assetsUrl}RemoveSchedule-3.png`;
const RemoveScheduleFourth = `${envConfig.assetsUrl}RemoveSchedule-4.png`;

const AddExpenseFirst = `${envConfig.assetsUrl}AddExpense-1.png`;
const AddExpenseSecond = `${envConfig.assetsUrl}AddExpense-2.png`;
const AddExpenseThird = `${envConfig.assetsUrl}AddExpense-3.png`;
const AddExpenseFourth = `${envConfig.assetsUrl}AddExpense-4.png`;
const AddExpenseFifth = `${envConfig.assetsUrl}AddExpense-5.png`;
const AddExpenseSixth = `${envConfig.assetsUrl}AddExpense-6.png`;
const AddExpenseSeventh = `${envConfig.assetsUrl}AddExpense-7.png`;

const CompleteHJOFirstA = `${envConfig.assetsUrl}CompleteHJO-1-1.png`;
const CompleteHJOFirstB = `${envConfig.assetsUrl}CompleteHJO-1-2.png`;
const CompleteHJOSecondA = `${envConfig.assetsUrl}CompleteHJO-2-1.png`;
const CompleteHJOSecondB = `${envConfig.assetsUrl}CompleteHJO-2-2.png`;
const CompleteHJOThird = `${envConfig.assetsUrl}CompleteHJO-3.png`;
const CompleteHJOFourthA = `${envConfig.assetsUrl}CompleteHJO-4-1.png`;
const CompleteHJOFourthB = `${envConfig.assetsUrl}CompleteHJO-4-2.png`;

const LocationAddFirstA = `${envConfig.assetsUrl}LocationAdd-1-1.png`;
const LocationAddFirstB = `${envConfig.assetsUrl}LocationAdd-1-2.png`;
const LocationAddSecond = `${envConfig.assetsUrl}LocationAdd-2.png`;
const LocationAddThird = `${envConfig.assetsUrl}LocationAdd-3.png`;
const LocationAddFourth = `${envConfig.assetsUrl}LocationAdd-4.png`;

const LocationImportFirst = `${envConfig.assetsUrl}LocationImport-1.png`;
const LocationImportSecond = `${envConfig.assetsUrl}LocationImport-2.png`;
const LocationImportThird = `${envConfig.assetsUrl}LocationImport-3.png`;
const LocationImportFourth = `${envConfig.assetsUrl}LocationImport-4.png`;
const LocationImportFifth = `${envConfig.assetsUrl}LocationImport-5.png`;
const LocationImportSixth = `${envConfig.assetsUrl}LocationImport-6.png`;

const LocationDeleteFirst = `${envConfig.assetsUrl}LocationDelete-1.png`;
const LocationDeleteSecond = `${envConfig.assetsUrl}LocationDelete-2.png`;
const LocationDeleteThird = `${envConfig.assetsUrl}LocationDelete-3.png`;
const LocationDeleteFourth = `${envConfig.assetsUrl}LocationDelete-4.png`;

const JOCreateFirst = `${envConfig.assetsUrl}JOCreate-1.png`;
const JOCreateSecond = `${envConfig.assetsUrl}JOCreate-2.png`;
const JOCreateThird = `${envConfig.assetsUrl}JOCreate-3.png`;
const JOCreateFourth = `${envConfig.assetsUrl}JOCreate-4.png`;
const JOCreateFifth = `${envConfig.assetsUrl}JOCreate-5.png`;
const JOCreateSixth = `${envConfig.assetsUrl}JOCreate-6.png`;
const JOCreateSeventhA = `${envConfig.assetsUrl}JOCreate-7-1.png`;
const JOCreateSeventhB = `${envConfig.assetsUrl}JOCreate-7-2.png`;
const JOCreateEighth = `${envConfig.assetsUrl}JOCreate-8.png`;
const JOCreateNinthOneOneA = `${envConfig.assetsUrl}JOCreate-9-1-1-1.png`;
const JOCreateNinthOneOneB = `${envConfig.assetsUrl}JOCreate-9-1-1-2.png`;
const JOCreateNinthOneOneC = `${envConfig.assetsUrl}JOCreate-9-1-1-3.png`;
const JOCreateNinthOneTwoA = `${envConfig.assetsUrl}JOCreate-9-1-2-1.png`;
const JOCreateNinthOneTwoB = `${envConfig.assetsUrl}JOCreate-9-1-2-2.png`;
const JOCreateNinthTwoOne = `${envConfig.assetsUrl}JOCreate-9-2-1.png`;
const JOCreateNinthTwoTwo = `${envConfig.assetsUrl}JOCreate-9-2-2.png`;
const JOCreateNinthTwoThreeA = `${envConfig.assetsUrl}JOCreate-9-2-3-1.png`;
const JOCreateNinthTwoThreeB = `${envConfig.assetsUrl}JOCreate-9-2-3-2.png`;
const JOCreateTenth = `${envConfig.assetsUrl}JOCreate-10.png`;

const JOEditDraftFirst = `${envConfig.assetsUrl}JOEditDraft-1.png`;
const JOEditDraftTwo = `${envConfig.assetsUrl}JOEditDraft-2.png`;
const JOEditDraftThreeA = `${envConfig.assetsUrl}JOEditDraft-3-1.png`;
const JOEditDraftThreeB = `${envConfig.assetsUrl}JOEditDraft-3-2.png`;

const CompleteJOFirstA = `${envConfig.assetsUrl}CompleteJo-1-1.png`;
const CompleteJOFirstB = `${envConfig.assetsUrl}CompleteJo-1-2.png`;
const CompleteJOSecondA = `${envConfig.assetsUrl}CompleteJo-2-1.png`;
const CompleteJOSecondB = `${envConfig.assetsUrl}CompleteJo-2-2.png`;
const CompleteJOThirdA = `${envConfig.assetsUrl}CompleteJo-3-1.png`;
const CompleteJOThirdB = `${envConfig.assetsUrl}CompleteJo-3-2.png`;
const CompleteJOFourthA = `${envConfig.assetsUrl}CompleteJo-4-1.png`;
const CompleteJOFourthB = `${envConfig.assetsUrl}CompleteJo-4-2.png`;
const PerformanceReportFirstA = `${envConfig.assetsUrl}PerformanceReport-1-1.png`;
const PerformanceReportFirstB = `${envConfig.assetsUrl}PerformanceReport-1-2.png`;
const PerformanceReportSecondA = `${envConfig.assetsUrl}PerformanceReport-2-1.png`;
const PerformanceReportSecondB = `${envConfig.assetsUrl}PerformanceReport-2-2.png`;

const SOCreateLastMileFirst = `${envConfig.assetsUrl}SOCreateLastMile-1.png`;
const SOCreateLastMileSecond = `${envConfig.assetsUrl}SOCreateLastMile-2.png`;
const SOCreateLastMileThird = `${envConfig.assetsUrl}SOCreateLastMile-3.png`;
const SOCreateLastMileFourth = `${envConfig.assetsUrl}SOCreateLastMile-4.png`;
const SOCreateLastMileFifth = `${envConfig.assetsUrl}SOCreateLastMile-5.png`;
const SOCreateLastMileSixth = `${envConfig.assetsUrl}SOCreateLastMile-6.png`;
const SOCreateLastMileSeventh = `${envConfig.assetsUrl}SOCreateLastMile-7.png`;
const SOCreateLastMileEighth = `${envConfig.assetsUrl}SOCreateLastMile-8.png`;
const SOCreateLastMileNinthA = `${envConfig.assetsUrl}SOCreateLastMile-9-1.png`;
const SOCreateLastMileNinthB = `${envConfig.assetsUrl}SOCreateLastMile-9-2.png`;
const SOCreateLastMileTenth = `${envConfig.assetsUrl}SOCreateLastMile-10.png`;
const SOCreateLastMileEleventh = `${envConfig.assetsUrl}SOCreateLastMile-11.png`;
const SOCreateLastMileTwelfth = `${envConfig.assetsUrl}SOCreateLastMile-12.png`;
const SOCreateLastMileThirteenthOneOneA = `${envConfig.assetsUrl}SOCreateLastMile-13-1-1-1.png`;
const SOCreateLastMileThirteenthOneOneB = `${envConfig.assetsUrl}SOCreateLastMile-13-1-1-2.png`;
const SOCreateLastMileThirteenthOneOneC = `${envConfig.assetsUrl}SOCreateLastMile-13-1-1-3.png`;
const SOCreateLastMileThirteenthOneTwoA = `${envConfig.assetsUrl}SOCreateLastMile-13-1-2-1.png`;
const SOCreateLastMileThirteenthOneTwoB = `${envConfig.assetsUrl}SOCreateLastMile-13-1-2-2.png`;
const SOCreateLastMileThirteenthTwoOne = `${envConfig.assetsUrl}SOCreateLastMile-13-2-1.png`;
const SOCreateLastMileThirteenthTwoTwo = `${envConfig.assetsUrl}SOCreateLastMile-13-2-2.png`;
const SOCreateLastMileThirteenthTwoThreeA = `${envConfig.assetsUrl}SOCreateLastMile-13-2-3-1.png`;
const SOCreateLastMileThirteenthTwoThreeB = `${envConfig.assetsUrl}SOCreateLastMile-13-2-3-2.png`;

const SOCreateTransitFirst = `${envConfig.assetsUrl}SOCreateTransit-1.png`;
const SOCreateTransitSecond = `${envConfig.assetsUrl}SOCreateTransit-2.png`;
const SOCreateTransitThird = `${envConfig.assetsUrl}SOCreateTransit-3.png`;
const SOCreateTransitFourthA = `${envConfig.assetsUrl}SOCreateTransit-4-1.png`;
const SOCreateTransitFourthB = `${envConfig.assetsUrl}SOCreateTransit-4-2.png`;
const SOCreateTransitFifth = `${envConfig.assetsUrl}SOCreateTransit-5.png`;
const SOCreateTransitSixth = `${envConfig.assetsUrl}SOCreateTransit-6.png`;
const SOCreateTransitSeventh = `${envConfig.assetsUrl}SOCreateTransit-7.png`;
const SOCreateTransitEighth = `${envConfig.assetsUrl}SOCreateTransit-8.png`;
const SOCreateTransitNinth = `${envConfig.assetsUrl}SOCreateTransit-9.png`;
const SOCreateTransitTenth = `${envConfig.assetsUrl}SOCreateTransit-10.png`;

const SOCreateTemplateFirst = `${envConfig.assetsUrl}SOCreateTemplate-1.png`;
const SOCreateTemplateSecond = `${envConfig.assetsUrl}SOCreateTemplate-2.png`;
const SOCreateTemplateThird = `${envConfig.assetsUrl}SOCreateTemplate-3.png`;
const SOCreateTemplateFourth = `${envConfig.assetsUrl}SOCreateTemplate-4.png`;
const SOCreateTemplateFifth = `${envConfig.assetsUrl}SOCreateTemplate-5.png`;
const SOCreateTemplateSixth = `${envConfig.assetsUrl}SOCreateTemplate-6.png`;
const SOCreateTemplateSeventh = `${envConfig.assetsUrl}SOCreateTemplate-7.png`;
const SOCreateTemplateEighthOneOneA = `${envConfig.assetsUrl}SOCreateTemplate-8-1-1-1.png`;
const SOCreateTemplateEighthOneOneB = `${envConfig.assetsUrl}SOCreateTemplate-8-1-1-2.png`;
const SOCreateTemplateEighthOneOneC = `${envConfig.assetsUrl}SOCreateTemplate-8-1-1-3.png`;
const SOCreateTemplateEighthOneTwoA = `${envConfig.assetsUrl}SOCreateTemplate-8-1-2-1.png`;
const SOCreateTemplateEighthOneTwoB = `${envConfig.assetsUrl}SOCreateTemplate-8-1-2-2.png`;
const SOCreateTemplateEighthTwoOne = `${envConfig.assetsUrl}SOCreateTemplate-8-2-1.png`;
const SOCreateTemplateEighthTwoTwo = `${envConfig.assetsUrl}SOCreateTemplate-8-2-2.png`;
const SOCreateTemplateEighthTwoThreeA = `${envConfig.assetsUrl}SOCreateTemplate-8-2-3-1.png`;
const SOCreateTemplateEighthTwoThreeB = `${envConfig.assetsUrl}SOCreateTemplate-8-2-3-2.png`;

const SOEditFirst = `${envConfig.assetsUrl}SOEdit-1.png`;
const SOEditSecondA = `${envConfig.assetsUrl}SOEdit-2-1.png`;
const SOEditSecondB = `${envConfig.assetsUrl}SOEdit-2-2.png`;
const SOEditSecondC = `${envConfig.assetsUrl}SOEdit-2-3.png`;
const SOEditThirdA = `${envConfig.assetsUrl}SOEdit-3-1.png`;
const SOEditThirdB = `${envConfig.assetsUrl}SOEdit-3-2.png`;
const SOEditFourth = `${envConfig.assetsUrl}SOEdit-4.png`;

const HOCreateFirst = `${envConfig.assetsUrl}HOCreate-1.png`;
const HOCreateSecond = `${envConfig.assetsUrl}HOCreate-2.png`;
const HOCreateThird = `${envConfig.assetsUrl}HOCreate-3.png`;
const HOCreateFourth = `${envConfig.assetsUrl}HOCreate-4.png`;
const HOCreateFifthA = `${envConfig.assetsUrl}HOCreate-5-1.png`;
const HOCreateFifthB = `${envConfig.assetsUrl}HOCreate-5-2.png`;
const HOCreateSixthOneOneA = `${envConfig.assetsUrl}HOCreate-6-1-1-1.png`;
const HOCreateSixthOneOneB = `${envConfig.assetsUrl}HOCreate-6-1-1-2.png`;
const HOCreateSixthOneOneC = `${envConfig.assetsUrl}HOCreate-6-1-1-3.png`;
const HOCreateSixthOneTwoA = `${envConfig.assetsUrl}HOCreate-6-1-2-1.png`;
const HOCreateSixthOneTwoB = `${envConfig.assetsUrl}HOCreate-6-1-2-2.png`;
const HOCreateSixthTwoOne = `${envConfig.assetsUrl}HOCreate-6-2-1.png`;
const HOCreateSixthTwoTwo = `${envConfig.assetsUrl}HOCreate-6-2-2.png`;
const HOCreateSixthTwoThreeA = `${envConfig.assetsUrl}HOCreate-6-2-3-1.png`;
const HOCreateSixthTwoThreeB = `${envConfig.assetsUrl}HOCreate-6-2-3-2.png`;
const HOCreateSeventh = `${envConfig.assetsUrl}HOCreate-7.png`;
const HOCreateEighth = `${envConfig.assetsUrl}HOCreate-8.png`;

const HJOCreateFirst = `${envConfig.assetsUrl}HJOCreate-1.png`;
const HJOCreateSecond = `${envConfig.assetsUrl}HJOCreate-2.png`;
const HJOCreateThird = `${envConfig.assetsUrl}HJOCreate-3.png`;
const HJOCreateFourth = `${envConfig.assetsUrl}HJOCreate-4.png`;
const HJOCreateFourthOneOneA = `${envConfig.assetsUrl}HJOCreate-4-1-1-1.png`;
const HJOCreateFourthOneOneB = `${envConfig.assetsUrl}HJOCreate-4-1-1-2.png`;
const HJOCreateFourthOneOneC = `${envConfig.assetsUrl}HJOCreate-4-1-1-3.png`;
const HJOCreateFourthOneTwoA = `${envConfig.assetsUrl}HJOCreate-4-1-2-1.png`;
const HJOCreateFourthOneTwoB = `${envConfig.assetsUrl}HJOCreate-4-1-2-2.png`;
const HJOCreateFourthTwoOne = `${envConfig.assetsUrl}HJOCreate-4-2-1.png`;
const HJOCreateFourthTwoTwo = `${envConfig.assetsUrl}HJOCreate-4-2-2.png`;
const HJOCreateFourthTwoThreeA = `${envConfig.assetsUrl}HJOCreate-4-2-3-1.png`;
const HJOCreateFourthTwoThreeB = `${envConfig.assetsUrl}HJOCreate-4-2-3-2.png`;
const HJOCreateFifth = `${envConfig.assetsUrl}HJOCreate-5.png`;

const HJOCreateViaDetailFirst = `${envConfig.assetsUrl}HJOCreateViaDetail-1.png`;
const HJOCreateViaDetailSecond = `${envConfig.assetsUrl}HJOCreateViaDetail-2.png`;
const HJOCreateViaDetailThird = `${envConfig.assetsUrl}HJOCreateViaDetail-3.png`;
const HJOCreateViaDetailFourth = `${envConfig.assetsUrl}HJOCreateViaDetail-4.png`;
const HJOCreateViaDetailFourthOneOneA = `${envConfig.assetsUrl}HJOCreateViaDetail-4-1-1-1.png`;
const HJOCreateViaDetailFourthOneOneB = `${envConfig.assetsUrl}HJOCreateViaDetail-4-1-1-2.png`;
const HJOCreateViaDetailFourthOneOneC = `${envConfig.assetsUrl}HJOCreateViaDetail-4-1-1-3.png`;
const HJOCreateViaDetailFourthOneTwoA = `${envConfig.assetsUrl}HJOCreateViaDetail-4-1-2-1.png`;
const HJOCreateViaDetailFourthOneTwoB = `${envConfig.assetsUrl}HJOCreateViaDetail-4-1-2-2.png`;
const HJOCreateViaDetailFourthTwoOne = `${envConfig.assetsUrl}HJOCreateViaDetail-4-2-1.png`;
const HJOCreateViaDetailFourthTwoTwo = `${envConfig.assetsUrl}HJOCreateViaDetail-4-2-2.png`;
const HJOCreateViaDetailFourthTwoThreeA = `${envConfig.assetsUrl}HJOCreateViaDetail-4-2-3-1.png`;
const HJOCreateViaDetailFourthTwoThreeB = `${envConfig.assetsUrl}HJOCreateViaDetail-4-2-3-2.png`;
const HJOCreateViaDetailFifth = `${envConfig.assetsUrl}HJOCreateViaDetail-5.png`;

const HJOCreateViaTableFirst = `${envConfig.assetsUrl}HJOCreateViaTable-1.png`;
const HJOCreateViaTableSecond = `${envConfig.assetsUrl}HJOCreateViaTable-2.png`;
const HJOCreateViaTableThirdOneOneA = `${envConfig.assetsUrl}HJOCreateViaTable-3-1-1-1.png`;
const HJOCreateViaTableThirdOneOneB = `${envConfig.assetsUrl}HJOCreateViaTable-3-1-1-2.png`;
const HJOCreateViaTableThirdOneOneC = `${envConfig.assetsUrl}HJOCreateViaTable-3-1-1-3.png`;
const HJOCreateViaTableThirdOneTwoA = `${envConfig.assetsUrl}HJOCreateViaTable-3-1-2-1.png`;
const HJOCreateViaTableThirdOneTwoB = `${envConfig.assetsUrl}HJOCreateViaTable-3-1-2-2.png`;
const HJOCreateViaTableThirdTwoOne = `${envConfig.assetsUrl}HJOCreateViaTable-3-2-1.png`;
const HJOCreateViaTableThirdTwoTwo = `${envConfig.assetsUrl}HJOCreateViaTable-3-2-2.png`;
const HJOCreateViaTableThirdTwoThreeA = `${envConfig.assetsUrl}HJOCreateViaTable-3-2-3-1.png`;
const HJOCreateViaTableThirdTwoThreeB = `${envConfig.assetsUrl}HJOCreateViaTable-3-2-3-2.png`;
const HJOCreateViaTableThirdThreeA = `${envConfig.assetsUrl}HJOCreateViaTable-3-3-1.png`;
const HJOCreateViaTableThirdThreeB = `${envConfig.assetsUrl}HJOCreateViaTable-3-3-2.png`;
const HJOCreateViaTableFourth = `${envConfig.assetsUrl}HJOCreateViaTable-4.png`;

const HOCompleteFirst = `${envConfig.assetsUrl}HOComplete-1.png`;
const HOCompleteSecond = `${envConfig.assetsUrl}HOComplete-2.png`;
const HOCompleteThird = `${envConfig.assetsUrl}HOComplete-3.png`;
const HOCompleteFourth = `${envConfig.assetsUrl}HOComplete-4.png`;

const HJOCompleteFirst = `${envConfig.assetsUrl}HJOComplete-1.png`;
const HJOCompleteSecond = `${envConfig.assetsUrl}HJOComplete-2.png`;
const HJOCompleteThird = `${envConfig.assetsUrl}HJOComplete-3.png`;

const AdditionalFunctionFilterFirst = `${envConfig.assetsUrl}AdditionalFunctionFilter-1.png`;
const AdditionalFunctionFilterSecondA = `${envConfig.assetsUrl}AdditionalFunctionFilter-2-1.png`;
const AdditionalFunctionFilterSecondB = `${envConfig.assetsUrl}AdditionalFunctionFilter-2-2.png`;
const AdditionalFunctionFilterSecondC = `${envConfig.assetsUrl}AdditionalFunctionFilter-2-3.png`;
const AdditionalFunctionFilterSecondD = `${envConfig.assetsUrl}AdditionalFunctionFilter-2-4.png`;
const AdditionalFunctionFilterThird = `${envConfig.assetsUrl}AdditionalFunctionFilter-3.png`;

const AdditionalFunctionPaginationFirstA = `${envConfig.assetsUrl}AdditionalFunctionPagination-1-1.png`;
const AdditionalFunctionPaginationFirstB = `${envConfig.assetsUrl}AdditionalFunctionPagination-1-2.png`;
const AdditionalFunctionPaginationSecond = `${envConfig.assetsUrl}AdditionalFunctionPagination-2.png`;

const AdditionalFunctionSearchFirstA = `${envConfig.assetsUrl}AdditionalFunctionSearch-1-1.png`;
const AdditionalFunctionSearchFirstB = `${envConfig.assetsUrl}AdditionalFunctionSearch-1-2.png`;

const JOGuide = `${envConfig.assetsUrl}jo-guide.pdf`;

export {
  DriverAddImportDriverFirst,
  DriverAddImportDriverSecond,
  DriverAddImportDriverThird,
  DriverAddImportDriverFourth,
  DriverAddImportDriverFifth,
  DriverAddImportDriverSixth,
  DriverAddInputManualFirstA,
  DriverAddInputManualFirstB,
  DriverAddInputManualSecond,
  DriverAddInputManualThird,
  DriverAddInputManualFourthA,
  DriverAddInputManualFourthB,
  EditDriverFirst,
  EditDriverSecond,
  EditDriverThird,
  EditDriverFourth,
  AddScheduleFirst,
  AddScheduleSecond,
  RemoveScheduleFirst,
  RemoveScheduleSecond,
  RemoveScheduleThird,
  RemoveScheduleFourth,
  NonActiveDriverFirst,
  NonActiveDriverSecond,
  NonActiveDriverThird,
  NonActiveDriverFourth,
  LocationAddFirstA,
  LocationAddFirstB,
  LocationAddSecond,
  LocationAddThird,
  LocationAddFourth,
  LocationImportFirst,
  LocationImportSecond,
  LocationImportThird,
  LocationImportFourth,
  LocationImportFifth,
  LocationImportSixth,
  LocationDeleteFirst,
  LocationDeleteSecond,
  LocationDeleteThird,
  LocationDeleteFourth,
  AddExpenseFirst,
  AddExpenseSecond,
  AddExpenseThird,
  AddExpenseFourth,
  AddExpenseFifth,
  AddExpenseSixth,
  AddExpenseSeventh,
  CompleteHJOFirstA,
  CompleteHJOFirstB,
  CompleteHJOSecondA,
  CompleteHJOSecondB,
  CompleteHJOThird,
  CompleteHJOFourthA,
  CompleteHJOFourthB,
  JOCreateFirst,
  JOCreateSecond,
  JOCreateThird,
  JOCreateFourth,
  JOCreateFifth,
  JOCreateSixth,
  JOCreateSeventhA,
  JOCreateSeventhB,
  JOCreateEighth,
  JOCreateNinthOneOneA,
  JOCreateNinthOneOneB,
  JOCreateNinthOneOneC,
  JOCreateNinthOneTwoA,
  JOCreateNinthOneTwoB,
  JOCreateNinthTwoOne,
  JOCreateNinthTwoTwo,
  JOCreateNinthTwoThreeA,
  JOCreateNinthTwoThreeB,
  JOCreateTenth,
  JOEditDraftFirst,
  JOEditDraftTwo,
  JOEditDraftThreeA,
  JOEditDraftThreeB,
  CompleteJOFirstA,
  CompleteJOFirstB,
  CompleteJOSecondA,
  CompleteJOSecondB,
  CompleteJOThirdA,
  CompleteJOThirdB,
  CompleteJOFourthA,
  CompleteJOFourthB,
  PerformanceReportFirstA,
  PerformanceReportFirstB,
  PerformanceReportSecondA,
  PerformanceReportSecondB,
  SOCreateLastMileFirst,
  SOCreateLastMileSecond,
  SOCreateLastMileThird,
  SOCreateLastMileFourth,
  SOCreateLastMileFifth,
  SOCreateLastMileSixth,
  SOCreateLastMileSeventh,
  SOCreateLastMileEighth,
  SOCreateLastMileNinthA,
  SOCreateLastMileNinthB,
  SOCreateLastMileTenth,
  SOCreateLastMileEleventh,
  SOCreateLastMileTwelfth,
  SOCreateLastMileThirteenthOneOneA,
  SOCreateLastMileThirteenthOneOneB,
  SOCreateLastMileThirteenthOneOneC,
  SOCreateLastMileThirteenthOneTwoA,
  SOCreateLastMileThirteenthOneTwoB,
  SOCreateLastMileThirteenthTwoOne,
  SOCreateLastMileThirteenthTwoTwo,
  SOCreateLastMileThirteenthTwoThreeA,
  SOCreateLastMileThirteenthTwoThreeB,
  SOCreateTransitFirst,
  SOCreateTransitSecond,
  SOCreateTransitThird,
  SOCreateTransitFourthA,
  SOCreateTransitFourthB,
  SOCreateTransitFifth,
  SOCreateTransitSixth,
  SOCreateTransitSeventh,
  SOCreateTransitEighth,
  SOCreateTransitNinth,
  SOCreateTransitTenth,
  SOCreateTemplateFirst,
  SOCreateTemplateSecond,
  SOCreateTemplateThird,
  SOCreateTemplateFourth,
  SOCreateTemplateFifth,
  SOCreateTemplateSixth,
  SOCreateTemplateSeventh,
  SOCreateTemplateEighthOneOneA,
  SOCreateTemplateEighthOneOneB,
  SOCreateTemplateEighthOneOneC,
  SOCreateTemplateEighthOneTwoA,
  SOCreateTemplateEighthOneTwoB,
  SOCreateTemplateEighthTwoOne,
  SOCreateTemplateEighthTwoTwo,
  SOCreateTemplateEighthTwoThreeA,
  SOCreateTemplateEighthTwoThreeB,
  SOEditFirst,
  SOEditSecondA,
  SOEditSecondB,
  SOEditSecondC,
  SOEditThirdA,
  SOEditThirdB,
  SOEditFourth,
  HOCreateFirst,
  HOCreateSecond,
  HOCreateThird,
  HOCreateFourth,
  HOCreateFifthA,
  HOCreateFifthB,
  HOCreateSixthOneOneA,
  HOCreateSixthOneOneB,
  HOCreateSixthOneOneC,
  HOCreateSixthOneTwoA,
  HOCreateSixthOneTwoB,
  HOCreateSixthTwoOne,
  HOCreateSixthTwoTwo,
  HOCreateSixthTwoThreeA,
  HOCreateSixthTwoThreeB,
  HOCreateSeventh,
  HOCreateEighth,
  HJOCreateFirst,
  HJOCreateSecond,
  HJOCreateThird,
  HJOCreateFourth,
  HJOCreateFourthOneOneA,
  HJOCreateFourthOneOneB,
  HJOCreateFourthOneOneC,
  HJOCreateFourthOneTwoA,
  HJOCreateFourthOneTwoB,
  HJOCreateFourthTwoOne,
  HJOCreateFourthTwoTwo,
  HJOCreateFourthTwoThreeA,
  HJOCreateFourthTwoThreeB,
  HJOCreateViaDetailFirst,
  HJOCreateViaDetailSecond,
  HJOCreateViaDetailThird,
  HJOCreateViaDetailFourth,
  HJOCreateViaDetailFourthOneOneA,
  HJOCreateViaDetailFourthOneOneB,
  HJOCreateViaDetailFourthOneOneC,
  HJOCreateViaDetailFourthOneTwoA,
  HJOCreateViaDetailFourthOneTwoB,
  HJOCreateViaDetailFourthTwoOne,
  HJOCreateViaDetailFourthTwoTwo,
  HJOCreateViaDetailFourthTwoThreeA,
  HJOCreateViaDetailFourthTwoThreeB,
  HJOCreateViaDetailFifth,
  HJOCreateViaTableFirst,
  HJOCreateViaTableSecond,
  HJOCreateViaTableThirdOneOneA,
  HJOCreateViaTableThirdOneOneB,
  HJOCreateViaTableThirdOneOneC,
  HJOCreateViaTableThirdOneTwoA,
  HJOCreateViaTableThirdOneTwoB,
  HJOCreateViaTableThirdTwoOne,
  HJOCreateViaTableThirdTwoTwo,
  HJOCreateViaTableThirdTwoThreeA,
  HJOCreateViaTableThirdTwoThreeB,
  HJOCreateViaTableThirdThreeA,
  HJOCreateViaTableThirdThreeB,
  HJOCreateViaTableFourth,
  HJOCreateFifth,
  HOCompleteFirst,
  HOCompleteSecond,
  HOCompleteThird,
  HOCompleteFourth,
  HJOCompleteFirst,
  HJOCompleteSecond,
  HJOCompleteThird,
  DriverContestRegisterFirstA,
  DriverContestRegisterFirstB,
  DriverContestRegisterSecond,
  DriverContestRegisterThird,
  DriverContestRegisterFourth,
  DriverContestRegisterFifth,
  DriverContestRegisterSixth,
  DriverContestRemovalFirstA,
  DriverContestRemovalFirstB,
  DriverContestRemovalSecond,
  DriverContestRemovalThird,
  DriverContestRemovalFourth,
  DriverContestRemovalFifth,
  AdditionalFunctionFilterFirst,
  AdditionalFunctionFilterSecondA,
  AdditionalFunctionFilterSecondB,
  AdditionalFunctionFilterSecondC,
  AdditionalFunctionFilterSecondD,
  AdditionalFunctionFilterThird,
  AdditionalFunctionPaginationFirstA,
  AdditionalFunctionPaginationFirstB,
  AdditionalFunctionPaginationSecond,
  AdditionalFunctionSearchFirstA,
  AdditionalFunctionSearchFirstB,
  JOGuide,
};
