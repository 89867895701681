import getUnixTime from 'date-fns/getUnixTime';
import parse from 'date-fns/parse';
import { BulkActionStatusEnum, SOActivityType } from '../../../constant';
import {
  ShipperOrderCreateBulkParamsData,
  ShipperOrderCreateBulkTableError,
  SOCreateBulkParamsActivityData,
  SOCreateBulkParamsActivityGoodsData,
} from '../../../model/ShipperOrder.model';
import { ErrorDetail } from '../../../service/api.endpoint';
import {
  mapActivityTypeFromExcelToEnum,
  mapGoodsTypeFromExcelToEnum,
} from '../../../util/shipperOrder.util';
import { CreateBulkSOEntity } from '../hooks/useSOCreateBulkLogic.hook';
import { SOCreateBulk } from '../soCreateBulk.route';

/**
 * Transforms API error details into a structured table error format for Shipper Order bulk creation
 * @param details - Array of error details from the API response
 * @returns Structured error object for displaying in the bulk creation table, or null if no details provided
 *
 * The returned error object contains:
 * - activities: Array of activity errors with their indices
 * - Each activity error can contain:
 *   - index: The position of the activity
 *   - errorObject: The specific field that caused the error
 *   - message: Error message
 *   - goods: Array of goods-related errors within the activity
 */
export function getSOCreateBulkTableErrorFromDetails(details?: ErrorDetail[]) {
  if (!details) return null;

  const result: ShipperOrderCreateBulkTableError = {
    activities: [],
  };

  for (let i = 0; i < details.length; i++) {
    let { key, message } = details[i];

    // Handle Activity Error
    message = 'Invalid Activity';

    // Match activities indices (e.g., activities[2])
    const activityMatch = key?.match(/activities\[(\d+)\]/);
    const activityIndex = activityMatch ? Number(activityMatch[1]) : null;

    if (activityIndex !== null) {
      // Check if the activity index is already present
      let activity = result.activities.find((a) => a.index === activityIndex);
      if (!activity) {
        activity = {
          index: activityIndex,
          errorObject: null,
          message: '',
          goods: [],
        };
        result.activities.push(activity);
      }

      // Assign the message and determine the error object for activities
      if (key?.includes('goods')) {
        const goodsMatch = key?.match(/goods\[(\d+)\]/);
        const goodsIndex = goodsMatch ? Number(goodsMatch[1]) : null;
        if (goodsIndex !== null) {
          // Check if the goods index is already present within this activity
          let goods = activity.goods?.find((g) => g.index === goodsIndex);
          if (!goods) {
            goods = { index: goodsIndex, errorObject: null };
            activity.goods?.push(goods);
          }
          // Assign the error object for the goods
          goods.errorObject = key.split('.').pop();
          message = 'Invalid Goods';
        }
      } else {
        activity.errorObject = key?.split('.').pop();
      }

      // Assign the cleaned or default message
      activity.message = message;
    }
  }

  return result;
}

/**
 * Converts a date string to Unix timestamp
 * @param dateString - Date string in format "dd/MM/yyyy" or "dd/MM/yyyy HH:mm"
 * @param isDateTime - If true, includes time in parsing (HH:mm), otherwise date only
 * @returns Unix timestamp (seconds since epoch)
 *
 * Examples:
 * - transformDateStringToUnixTimestamp("25/12/2023") // Returns timestamp for Dec 25, 2023
 * - transformDateStringToUnixTimestamp("25/12/2023 14:30", true) // Returns timestamp for Dec 25, 2023 14:30
 */
export function transformDateStringToUnixTimestamp(
  dateString?: string,
  isDateTime?: boolean,
): number | undefined {
  const formatStr = isDateTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
  const parsedDate = dateString
    ? parse(dateString, formatStr, new Date())
    : new Date();
  const isDateInvalid = parsedDate?.toString() === 'Invalid Date';
  return isDateInvalid
    ? undefined
    : Math.floor(getUnixTime(isDateInvalid ? new Date() : parsedDate));
}

/**
 * Transforms a single row of bulk upload data into the activity data structure
 * @param item - Single row of data from the bulk upload Excel
 * @returns Formatted activity data object ready for API submission
 *
 * Transforms Excel columns into an activity object containing:
 * - index: Activity sequence number
 * - locationName: Standby location
 * - type: Activity type (mapped from Excel to enum)
 * - expectedFinishAt: Expected completion timestamp
 * - goods: Array of goods details (if any)
 */
export function transformSOCreateBulkToActivityData(
  item: SOCreateBulk,
): SOCreateBulkParamsActivityData {
  const goods = {
    type: mapGoodsTypeFromExcelToEnum(item['Tipe Barang']),
    ...(item.Satuan && { uom: item.Satuan?.toString() }),
    ...(item.Keterangan && { description: item.Keterangan?.toString() }),
    ...(item.Jumlah && { quantity: item.Jumlah }),
    ...(item['Volume (m3)'] && { volume: item['Volume (m3)'] }),
    ...(item['Berat (kg)'] && { weight: item['Berat (kg)'] }),
  };

  const expectedFinishAt = item?.['Perkiraan Waktu']
    ? transformDateStringToUnixTimestamp(item?.['Perkiraan Waktu'], true)
    : undefined;

  return {
    index: item['Aktivitas*'],
    ...(item['Lokasi*'] && { locationName: item['Lokasi*']?.toString() }),
    type: mapActivityTypeFromExcelToEnum(item['Tipe Aktivitas*']),
    expectedFinishAt,
    goods: goods.type
      ? [
          {
            type: goods.type,
            ...(goods.volume && { volume: goods.volume }),
            ...(goods.weight && { weight: goods.weight }),
            ...(goods.quantity && { quantity: goods.quantity }),
            ...(goods.description && { description: goods.description }),
            ...(goods.uom && { uom: goods.uom }),
          },
        ]
      : [],
  };
}

/**
 * Transforms bulk upload data into grouped Shipper Order entities
 * @param shipperOrders - Array of raw data from bulk upload Excel
 * @returns Array of formatted Shipper Order entities ready for processing
 *
 * Key features:
 * - Groups activities by order number
 * - Combines goods for activities with same index
 * - Sorts activities by index
 * - Adds default status and action details
 * - Formats cost information
 *
 * The returned entities include all required fields for API submission plus
 * additional fields for UI state management (_actionDetail, _actionStatus)
 */
export function transformSOBulkStoreToState(
  shipperOrders: SOCreateBulk[],
): CreateBulkSOEntity[] {
  const groupedByOrder = shipperOrders?.reduce<
    Record<string, Omit<ShipperOrderCreateBulkParamsData, 'soNumber'>>
  >((acc, item) => {
    const orderNumber = item['Nomor Order*'];

    if (!acc[orderNumber]) {
      acc[orderNumber] = {
        ...(item['Nomor Referensi'] && {
          referenceNumber: item['Nomor Referensi']?.toString(),
        }),
        ...(item['Nomor Resi'] && {
          trackingCode: item['Nomor Resi']?.toString(),
        }),
        ...(item['Nama Pengirim*'] && {
          shipperName: item['Nama Pengirim*']?.toString(),
        }),
        ...(item.Catatan && { notes: item.Catatan?.toString() }),
        soDate: transformDateStringToUnixTimestamp(
          item['Tanggal Shipper Order*'],
          false,
        ),
        isTransitable: false,
        cost: {
          deliveryFee: 0,
          tax: 0,
          insurance: 0,
        },
        activities: [],
      };
    }
    acc[orderNumber].cost.deliveryFee += item?.['Biaya Kirim Barang'] || 0;
    acc[orderNumber].cost.tax += item?.Pajak || 0;
    acc[orderNumber].cost.insurance += item?.Asuransi || 0;

    const activity = transformSOCreateBulkToActivityData(item);
    const existingActivity = acc[orderNumber].activities.find(
      (act) => act.index === activity.index,
    );

    if (existingActivity?.goods) {
      existingActivity.goods.push(
        ...(activity.goods as SOCreateBulkParamsActivityGoodsData[]),
      );
    } else {
      acc[orderNumber].activities.push(activity);
    }

    return acc;
  }, {});

  return Object.entries(groupedByOrder).map(([soNumber, order]) => ({
    ...order,
    activities: order.activities
      .map((item, idx) => ({
        index: idx,
        locationName: item.locationName,
        type: item.type,
        expectedFinishAt: item.expectedFinishAt,
        ...(item.type !== SOActivityType.STAND_BY && { goods: item.goods }),
      }))
      .sort((item) => item.index),
    soNumber: soNumber === 'undefined' ? undefined : soNumber?.toString(),
    _actionDetail: '',
    _actionStatus: BulkActionStatusEnum.WAITING,
  }));
}
// #endregion
