import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../app/store/store.app';
import {
  BulkActionEntity,
  BulkActionStatusEnum,
  ErrorCodes,
} from '../../../constant';
import useOrganizationOrientation from '../../../hook/organization/useOrganizationOrientation.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import {
  ShipperOrderCreateBulkParamsData,
  SOCreateBulkParamsActivityData,
  SOCreateBulkParamsCostData,
} from '../../../model/ShipperOrder.model';
import { logEvent } from '../../../service/analytic/analytic.service';
import { ApiErrorResponse } from '../../../service/api.endpoint';
import api from '../../../service/api.service';
import { navigationParamAction } from '../../../store/param.store';
import { errorCodeToLabel } from '../../../util/error.util';
import { shipperOrderRoute } from '../../ShipperOrder/ShipperOrder.route';
import useVerifyAuth from '../../Wrapper/hooks/useVerifyAuth.hook';
import {
  getSOCreateBulkTableErrorFromDetails,
  transformSOBulkStoreToState,
} from '../utils/soCreateBulk.util';

// #region Types
const UNDEFINED_SHIPPER_ORDER_NUMBER = 'undefined-shipper-order-number';
export type CreateBulkSOEntity =
  BulkActionEntity<ShipperOrderCreateBulkParamsData>;
export type UseSOCreateBulkLogic = ReturnType<typeof useSOCreateBulkLogic>;
export type MappedSOCreateBulk = Record<
  string,
  {
    soNumber: string;
    soDate: number;
    trackingCode?: string;
    referenceNumber?: string;
    shipperName: string;
    notes?: string;
    isTransitable: boolean;
    cost: SOCreateBulkParamsCostData;
    activities: SOCreateBulkParamsActivityData[];
  }
>;
// #endregion

export default function useSOCreateBulkLogic() {
  const { translate } = useTranslator();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleVerifyAuth } = useVerifyAuth();
  const { handleFetchOrganizationData } = useOrganizationOrientation();

  // #region VALUES
  const [showStatus, setShowStatus] = useState(false);

  const [truncatedFilename, setTruncatedFilename] = useState('');

  const { filename, shipperOrders: shipperOrderStore } = useSelector(
    (state: RootState) => {
      return (
        state.navigationParam.soCreateBulkParams ?? {
          filename: '',
          shipperOrders: [],
        }
      );
    },
  );

  const [shipperOrdersWithStatusDetail, setShipperOrdersWithStatusDetail] =
    useState<CreateBulkSOEntity[]>(
      transformSOBulkStoreToState(shipperOrderStore),
    );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);
  const [showDoneBtn, setShowDoneBtn] = useState(false);
  // to disable submit button when mutations is still ongoing
  const [mutationsIsLoading, setMutationsIsLoading] = useState(false);
  const [doCreateShipperOrder] = api.useCreateShipperOrderBulkMutation();
  // #endregion

  // #region HANDLERS
  const handleBulkCreationError = useCallback(
    (errorQuery: ApiErrorResponse, soNumber: string) => {
      const errorCode = errorQuery?.error?.code;
      const errorDetails = errorQuery.error.details;
      const transformedErrorDetails =
        getSOCreateBulkTableErrorFromDetails(errorDetails);
      const errorMessage =
        transformedErrorDetails?.activities?.[0]?.message ??
        errorDetails ??
        translate('Invalid arguments');

      setShipperOrdersWithStatusDetail((_shipperOrders) =>
        _shipperOrders.map((_shipperOrder) => {
          const currentSONumber =
            _shipperOrder.soNumber ?? UNDEFINED_SHIPPER_ORDER_NUMBER;
          return currentSONumber === soNumber
            ? {
                ..._shipperOrder,
                _actionStatus: BulkActionStatusEnum.FAILED,
                _actionDetail:
                  errorCode === ErrorCodes.REQUEST_INVALID_ARGUMENT
                    ? errorMessage
                    : errorCodeToLabel(errorCode),
                _actionActivityError: transformedErrorDetails,
              }
            : _shipperOrder;
        }),
      );
    },
    [translate],
  );

  const handleBulkCreationSuccess = useCallback(
    (soNumber: string) => {
      setShipperOrdersWithStatusDetail((_shipperOrders) =>
        _shipperOrders.map((_shipperOrder) =>
          _shipperOrder.soNumber === soNumber
            ? {
                ..._shipperOrder,
                _actionStatus: BulkActionStatusEnum.SUCCEED,
                _actionDetail: translate('Shipper order successfully created'),
              }
            : _shipperOrder,
        ),
      );
      logEvent('ShipperOrder:AddBulk');
    },
    [translate],
  );

  const handleAddBulk = useCallback(async () => {
    const authRes = await handleVerifyAuth();
    if (!authRes) return;
    setShowStatus(true);
    const promises = shipperOrdersWithStatusDetail.map((shipperOrder) =>
      doCreateShipperOrder({
        shipperOrders: [shipperOrder],
      }).unwrap(),
    );

    // track loading state
    setMutationsIsLoading(true);

    // NOTE: backend said this endpoint is safe to the parallel mutation
    await Promise.allSettled(promises)
      .then((results) => {
        for (const [idx, result] of results.entries()) {
          const currentSoNumber =
            shipperOrdersWithStatusDetail[idx].soNumber ??
            UNDEFINED_SHIPPER_ORDER_NUMBER;

          // on rejected
          if (result.status === 'rejected') {
            handleBulkCreationError(
              (result.reason as FetchBaseQueryError)?.data as ApiErrorResponse,
              currentSoNumber,
            );
            continue;
          }

          if (result.value.result.errors.length > 0) {
            handleBulkCreationError(
              {
                error: { code: result.value.result.errors[0].code },
              } as ApiErrorResponse,
              currentSoNumber,
            );
            continue;
          }

          // on fulfilled
          handleBulkCreationSuccess(currentSoNumber);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        // on done
        setShowDoneBtn(true);
        setMutationsIsLoading(false);
        setHasUnsavedChanges(false);
      });
  }, [
    doCreateShipperOrder,
    handleVerifyAuth,
    shipperOrdersWithStatusDetail,
    handleBulkCreationError,
    handleBulkCreationSuccess,
  ]);

  const handleDone = useCallback(() => {
    // clear store
    dispatch(navigationParamAction.clearJOCreateBulkParams());
    navigate(shipperOrderRoute.path);
    handleFetchOrganizationData();
  }, [dispatch, handleFetchOrganizationData, navigate]);

  // #endregion

  // sync redux store with state
  useEffect(() => {
    if (filename.length < 30) {
      setTruncatedFilename(filename);
      return;
    }
    const [name, format] = filename.split('.');
    const truncatedName = name.slice(0, 25);
    setTruncatedFilename(`${truncatedName}...${format}`);
  }, [filename]);

  // sync `shippersStore` with `shippersWithStatusDetail`
  useEffect(() => {
    if (shipperOrderStore.length > 0)
      setShipperOrdersWithStatusDetail(
        transformSOBulkStoreToState(shipperOrderStore),
      );
  }, [shipperOrderStore]);

  return {
    hasUnsavedChanges,
    filename,
    truncatedFilename,
    showDoneBtn,
    mutationsIsLoading,
    showStatus,
    shipperOrdersWithStatusDetail,
    handleDone,
    handleAddBulk,
  };
}
