import { useCallback, useState } from 'react';
import {
  ShipperOrderCreateBulkTableError,
  SOCreateBulkParamsActivityData,
} from '../../../model/ShipperOrder.model';

type ActivityListInfoModal = {
  activities: SOCreateBulkParamsActivityData[];
  failedActivities?: ShipperOrderCreateBulkTableError | null;
  isRequestCompleted?: boolean;
};

export default function useSOActivityListModal() {
  const [activityListInfoModal, setActivityListInfoModal] = useState<
    ActivityListInfoModal | undefined
  >(undefined);

  const onOpenActivityListModal = useCallback(
    ({
      activities,
      failedActivities,
      isRequestCompleted,
    }: ActivityListInfoModal) => {
      setActivityListInfoModal({
        activities,
        failedActivities,
        isRequestCompleted,
      });
    },
    [],
  );

  const onCloseActivityListModal = useCallback(() => {
    setActivityListInfoModal(undefined);
  }, []);

  return {
    activityListInfoModal,
    onOpenActivityListModal,
    onCloseActivityListModal,
  };
}

export type UseSOActivityListModal = ReturnType<typeof useSOActivityListModal>;
