import React from 'react';
import { Navigate } from 'react-router-dom';
import tw from 'twin.macro';
import { Icon, IconButton, TableNoData } from '../../component/atom';
import {
  BodyOne,
  HeadingThree,
  Label,
} from '../../component/atom/Text/Text.atom';
import {
  BeforeLeaveAlertModal,
  CollapsiblePanel,
  Modal,
  SOActivityTable,
} from '../../component/molecule';
import DrivingContestStatusBadge from '../../component/molecule/DrivingContestStatusBadge/DrivingContestStatusBadge.molecule';
import { UploadFileModal } from '../../component/organism';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import { SOActivityType } from '../../constant';
import soActivityColumns from '../../constant/Goods.constant';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import { DrivingContestStatus } from '../../model/DrivingContest.model';
import { numberFormatter } from '../../util/formatter.util';
import { getLongFormatDate } from '../../util/helper.util';
import { activityTypeToLabel } from '../../util/shipperOrder.util';
import {
  getGoodsTableTotal,
  transformActivityBulkToGoods,
} from '../../util/shipperOrderCreate.util';
import useSOCreateBulkViewModel from './SOCreateBulk.viewModel';

const ModalStyle = tw`p-0 max-w-[1024px] `;
const HeaderContentItemContainer = tw.div`flex flex-row gap-8 py-2 border-b-2 border-b-beige-lines animate-slide-in-left last-of-type:border-0`;

export default function JOCreateBulkView() {
  const { translator, uploadModal, bulkLogic, bulkTable, activitiesModal } =
    useSOCreateBulkViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <>
      <BulkActionLayout
        actionLabel={translator.translate('to start the creation process.')}
        descriptionTooltipLabel={bulkLogic.filename}
        descriptionLabel={translator.translate(
          'Make sure the list below is the shipper order list that you want to create. The shipper order created will be active and are ready to be shipped.',
        )}
        headerLabel={bulkLogic.truncatedFilename}
        cancelLabel={translator.translate('Re-upload')}
        submitLabel={translator.translate('Create')}
        doneLabel={translator.translate('Done')}
        table={bulkTable.table}
        entityLength={bulkLogic.shipperOrdersWithStatusDetail.length}
        showDoneBtn={bulkLogic.showDoneBtn}
        isLoading={bulkLogic.mutationsIsLoading}
        noDataPage={
          <TableNoData
            icon={<Icon.NoDataFound />}
            titleLabel={translator.translate(
              "You don't have any imported Shipper Orders that you want to create",
            )}
            descriptionLabel={translator.translate(
              'Try to import some Shipper Orders by clicking the Re-upload button, or you can click the button below',
            )}
            actionLabel={translator.translate('Re-upload')}
            onClickAction={uploadModal.onOpenUploadModal}
          />
        }
        onCancel={uploadModal.onOpenUploadModal}
        onBack={bulkLogic.handleDone}
        onSubmit={bulkLogic.handleAddBulk}
        onDone={bulkLogic.handleDone}
      />

      {uploadModal.showUploadModal && (
        <UploadFileModal
          filename={uploadModal.filename}
          error={uploadModal.error}
          dragActive={uploadModal.dragActive}
          isLoading={uploadModal.isLoading}
          requiredLabels={uploadModal.requiredLabels}
          sampleLink={uploadModal.sampleLink}
          onClose={uploadModal.onCloseUploadModal}
          handleReupload={uploadModal.handleReupload}
          handleDrag={uploadModal.handleDrag}
          handleDrop={uploadModal.handleDrop}
          handleChange={uploadModal.handleChange}
          subtitleLabel={uploadModal.subtitleLabel}
        />
      )}

      {activitiesModal.activityListInfoModal && (
        <Modal.Regular
          onOverlayClick={activitiesModal.onCloseActivityListModal}
          css={ModalStyle}
        >
          <div tw="flex flex-col">
            <div tw="flex flex-1 px-5 py-4 items-center bg-beige-bg border-b border-beige-lines rounded-t-md">
              <HeadingThree tw="flex flex-1 text-black-primary">{`${translator.translate(
                'Shipper Order Activity',
              )} (${
                activitiesModal.activityListInfoModal.activities.length
              })`}</HeadingThree>
              <IconButton onClick={activitiesModal.onCloseActivityListModal}>
                <Icon.Close tw="w-5 h-5 text-neutral-500" />
              </IconButton>
            </div>
            <div>
              <div tw="flex flex-col gap-6 px-5 py-6 max-h-[500px] overflow-y-scroll">
                {activitiesModal.activityListInfoModal.activities.map(
                  (activity, idx) => {
                    const isStatusFailed =
                      activitiesModal.activityListInfoModal?.failedActivities?.activities.find(
                        (failedActivity) =>
                          failedActivity.index === activity.index,
                      );

                    return (
                      <CollapsiblePanel
                        title={`${translator.translate('Activity')} ${idx + 1}`}
                        key={activity.index}
                        customAction={
                          activitiesModal.activityListInfoModal
                            ?.isRequestCompleted ? (
                            <DrivingContestStatusBadge
                              containerStyle={tw`self-center`}
                              status={
                                isStatusFailed
                                  ? DrivingContestStatus.NOT_READY
                                  : DrivingContestStatus.READY
                              }
                              statusLabel={
                                isStatusFailed
                                  ? translator.translate('Incorrect')
                                  : translator.translate('Correct')
                              }
                            />
                          ) : undefined
                        }
                      >
                        <div>
                          <HeaderContentItemContainer>
                            <Label tw="w-28 min-w-[7rem] text-grey-three whitespace-normal">
                              {translator.translate('Activity Type')}
                            </Label>
                            <BodyOne tw="truncate">
                              {activityTypeToLabel(activity.type)}
                            </BodyOne>
                          </HeaderContentItemContainer>
                          <HeaderContentItemContainer>
                            <Label tw="w-28 min-w-[7rem] text-grey-three whitespace-normal">
                              {translator.translate('Location')}
                            </Label>
                            <BodyOne tw="truncate">
                              {activity.locationName ?? '-'}
                            </BodyOne>
                          </HeaderContentItemContainer>
                          <HeaderContentItemContainer>
                            <Label tw="w-28 min-w-[7rem] text-grey-three whitespace-normal">
                              {translator.translate('Expected Time')}
                            </Label>
                            <BodyOne tw="truncate">
                              {activity.expectedFinishAt
                                ? getLongFormatDate(
                                    new Date(activity.expectedFinishAt * 1000),
                                    translator.currentLanguage,
                                    true,
                                  )
                                : '-'}
                            </BodyOne>
                          </HeaderContentItemContainer>
                        </div>
                        {activity.type !== SOActivityType.STAND_BY && (
                          <>
                            <div tw="mt-2.5" />
                            <CollapsiblePanel
                              title={translator.translate('Goods')}
                            >
                              <SOActivityTable
                                columns={soActivityColumns.map((v) => ({
                                  ...v,
                                  label: translator.translate(v.label),
                                }))}
                                rows={transformActivityBulkToGoods(
                                  activity.goods,
                                )}
                                total={{
                                  label: translator.translate('Total'),
                                  volume: numberFormatter(
                                    getGoodsTableTotal(
                                      'volume',
                                      activity.goods,
                                    ),
                                  ),
                                  weight: numberFormatter(
                                    getGoodsTableTotal(
                                      'weight',
                                      activity.goods,
                                    ),
                                  ),
                                }}
                                translator={translator}
                              />
                            </CollapsiblePanel>
                          </>
                        )}
                      </CollapsiblePanel>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </Modal.Regular>
      )}

      <BeforeLeaveAlertModal hasUnsavedChanges={bulkLogic.hasUnsavedChanges} />
    </>
  );
}
