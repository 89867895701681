// #region IMPORT
import urlcat from 'urlcat';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  CreateShipperOrderPaymentHistoryRequest,
  CreateShipperOrderPaymentHistoryResponse,
  DeleteShipperOrderApiRequest,
  DeleteShipperOrderApiResponse,
  DeleteShipperOrderPaymentHistoryRequest,
  DeleteShipperOrderPaymentHistoryResponse,
  ExportSOApiRequest,
  ExportSOApiResponse,
  GetShipperOrderPaymentHistoryListRequest,
  GetShipperOrderPaymentHistoryListResponse,
  GetSOApiRequest,
  GetSOApiResponse,
  GetSOByTrackingCodeApiRequest,
  GetSOByTrackingCodeApiResponse,
  GetSOCountApiRequest,
  GetSOCountApiResponse,
  GetSOUnassignedListParams,
  GetTrackingSORequest,
  GetTrackingSOResponse,
  Goods,
  ShipperOrderCreateBulkParams,
  ShipperOrderCreateBulkResponse,
  ShipperOrderCreateParam,
  ShipperOrderCreateResponse,
  ShipperOrderInfo,
  ShipperOrderUnassigned,
  SOActivitiesUpdateParam,
  SOActivitiesUpdateResponse,
  SOCostUpdateParam,
  SOCostUpdateResponse,
  SOHeaderUpdateParam,
  SOHeaderUpdateResponse,
} from '../../../model/ShipperOrder.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import { CommonApiMetadata, CommonApiResponse } from '../../api.endpoint';
import { organizationTagTypes } from '../organization/organization.endpoint';
// #endregion

// #region INTERFACES

export type ShipperOrderEndpoint = {
  getShipperOrderStatus: QD<
    { id: string },
    CommonApiResponse & { shipperOrder: { status: number; statusText: string } }
  >;
  getShipperOrdersCount: QD<
    GetSOCountApiRequest | string,
    GetSOCountApiResponse
  >;
  getShipperOrders: QD<GetSOApiRequest | string, GetSOApiResponse>;
  getShipperOrderInfo: QD<
    { id: string },
    CommonApiResponse & {
      shipperOrder: ShipperOrderInfo;
    }
  >;
  getSOUnassignedList: QD<
    GetSOUnassignedListParams | string,
    CommonApiResponse &
      CommonApiMetadata & {
        shipperOrders: ShipperOrderUnassigned[];
      }
  >;
  sendSODisclaimer: MD<{ soId: string }, void>;
  createShipperOrder: MD<ShipperOrderCreateParam, ShipperOrderCreateResponse>;
  updateSOHeader: MD<SOHeaderUpdateParam, SOHeaderUpdateResponse>;
  updateSOCost: MD<SOCostUpdateParam, SOCostUpdateResponse>;
  updateSOActivities: MD<SOActivitiesUpdateParam, SOActivitiesUpdateResponse>;
  goodsUpdate: MD<
    {
      soId: string;
      soActivityId: string;
      goods: Goods[];
    },
    CommonApiResponse
  >;
  createShipperOrderBulk: MD<
    ShipperOrderCreateBulkParams,
    ShipperOrderCreateBulkResponse
  >;
  getShipperOrderPaymentHistoryList: QD<
    GetShipperOrderPaymentHistoryListRequest,
    GetShipperOrderPaymentHistoryListResponse
  >;
  createShipperOrderPaymentHistory: MD<
    CreateShipperOrderPaymentHistoryRequest,
    CreateShipperOrderPaymentHistoryResponse
  >;
  deleteShipperOrderPaymentHistory: MD<
    DeleteShipperOrderPaymentHistoryRequest,
    DeleteShipperOrderPaymentHistoryResponse
  >;
  exportShipperOrder: MD<ExportSOApiRequest, ExportSOApiResponse>;
  getTrackingSO: QD<GetTrackingSORequest, GetTrackingSOResponse>;
  getSOByTrackingCode: QD<
    GetSOByTrackingCodeApiRequest,
    GetSOByTrackingCodeApiResponse
  >;
  getSOTrackPosition: QD<GetSOTrackPositionRequest, GetSOTrackPositionResponse>;
  deleteShipperOrder: MD<
    DeleteShipperOrderApiRequest,
    DeleteShipperOrderApiResponse
  >;
};

export type GetSOTrackPositionRequest = { trackingCode: string };
export type GetSOTrackPositionResponse = CommonApiResponse & {
  latestPosition: { latitude: number; longitude: number };
};
// #endregion

// #region ENDPOINT
export const shipperOrderTagTypes: EndpointTagTypes<ShipperOrderEndpoint> = {
  getShipperOrdersCount: '',
  getShipperOrderInfo: 'shipperOrder.info',
  getShipperOrders: 'shipperOrder',
  sendSODisclaimer: 'shipper-orders.share-disclaimer',
  getSOUnassignedList: '',
  createShipperOrder: '',
  updateSOHeader: '',
  updateSOCost: '',
  updateSOActivities: '',
  goodsUpdate: '',
  createShipperOrderBulk: '',
  getShipperOrderStatus: '',
  getShipperOrderPaymentHistoryList: 'shipperOrderPaymentHistory',
  createShipperOrderPaymentHistory: '',
  deleteShipperOrderPaymentHistory: '',
  exportShipperOrder: '',
  getTrackingSO: 'shipper-orders.timeline.list',
  getSOByTrackingCode: 'shipper-orders.track',
  getSOTrackPosition: 'shipper-orders.positions.track',
  deleteShipperOrder: '',
};

export const shipperOrderEndpoint = (
  builder: Builder,
): ShipperOrderEndpoint => ({
  getTrackingSO: builder.query({
    query: (param: GetTrackingSORequest) => ({
      url: urlcat('v1/shipper-orders.timeline.list?', param),
    }),
    providesTags: [shipperOrderTagTypes.getTrackingSO],
  }),
  getShipperOrdersCount: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/shipper-orders.count?${param}`
          : urlcat('/v1/shipper-orders.count', toSnakeCase(param)),
    }),
    providesTags: [shipperOrderTagTypes.getShipperOrders],
  }),
  getShipperOrderInfo: builder.query({
    query: (param) => ({
      url: urlcat('v1/shipper-orders.info', toSnakeCase(param)),
    }),
    providesTags: [shipperOrderTagTypes.getShipperOrderInfo],
  }),
  getShipperOrders: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/shipper-orders.list?${param}`
          : urlcat('/v1/shipper-orders.list', toSnakeCase(param)),
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.shipperOrders.map(({ id }) => ({
              type: shipperOrderTagTypes.getShipperOrders,
              id,
            })),
            shipperOrderTagTypes.getShipperOrders,
          ]
        : [shipperOrderTagTypes.getShipperOrders],
  }),
  getSOUnassignedList: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/shipper-orders.unassigned.list?${param}`
          : urlcat('/v1/shipper-orders.unassigned.list', toSnakeCase(param)),
    }),
  }),
  createShipperOrder: builder.mutation({
    query: (body) => ({
      url: '/v1/shipper-orders.create',
      body,
      method: 'post',
    }),
    invalidatesTags: [shipperOrderTagTypes.getShipperOrders],
  }),
  createShipperOrderBulk: builder.mutation({
    query: (body) => ({
      url: '/v1/shipper-orders.create.bulk',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      shipperOrderTagTypes.getShipperOrders,
      organizationTagTypes.getOrganizationMe,
    ],
  }),
  updateSOHeader: builder.mutation({
    query: (body) => ({
      url: '/v1/shipper-orders.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      shipperOrderTagTypes.getShipperOrders,
      shipperOrderTagTypes.getShipperOrderInfo,
    ],
  }),
  updateSOCost: builder.mutation({
    query: (body) => ({
      url: '/v1/shipper-orders.cost.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      shipperOrderTagTypes.getShipperOrders,
      shipperOrderTagTypes.getShipperOrderInfo,
    ],
  }),
  updateSOActivities: builder.mutation({
    query: (body) => ({
      url: '/v1/shipper-orders.activities.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      shipperOrderTagTypes.getShipperOrders,
      shipperOrderTagTypes.getShipperOrderInfo,
    ],
  }),
  goodsUpdate: builder.mutation({
    query: (body) => ({
      url: '/v1/shipper-orders.activities.goods.update',
      body,
      method: 'post',
    }),
    invalidatesTags: [shipperOrderTagTypes.getShipperOrderInfo],
  }),
  getShipperOrderStatus: builder.query({
    query: (param) => ({
      url: urlcat('v1/shipper-orders.info.status', toSnakeCase(param)),
    }),
  }),
  getShipperOrderPaymentHistoryList: builder.query({
    query: (param) => ({
      url: urlcat('/v1/shipper-orders.payments.list', toSnakeCase(param)),
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.payments.map(({ id }) => ({
              type: shipperOrderTagTypes.getShipperOrderPaymentHistoryList,
              id,
            })),
            shipperOrderTagTypes.getShipperOrderPaymentHistoryList,
          ]
        : [shipperOrderTagTypes.getShipperOrderPaymentHistoryList],
  }),
  createShipperOrderPaymentHistory: builder.mutation({
    query: (body) => ({
      body,
      url: '/v1/shipper-orders.payments.add',
      method: 'post',
    }),
    invalidatesTags: [
      shipperOrderTagTypes.getShipperOrders,
      shipperOrderTagTypes.getShipperOrderPaymentHistoryList,
    ],
  }),
  deleteShipperOrderPaymentHistory: builder.mutation({
    query: (body) => ({
      body,
      url: '/v1/shipper-orders.payments.remove',
      method: 'post',
    }),
    invalidatesTags: [
      shipperOrderTagTypes.getShipperOrders,
      shipperOrderTagTypes.getShipperOrderPaymentHistoryList,
    ],
  }),
  exportShipperOrder: builder.mutation({
    query: (body) => ({
      body,
      url: '/v1/shipper-orders.export',
      method: 'post',
    }),
  }),
  sendSODisclaimer: builder.mutation({
    query: (body) => ({
      body,
      url: '/v1/shipper-orders.share-disclaimer.add',
      method: 'post',
    }),
  }),
  getSOByTrackingCode: builder.query({
    query: (param) => ({
      url: urlcat('/v1/shipper-orders.track', toSnakeCase(param)),
    }),
    keepUnusedDataFor: 0,
    providesTags: [shipperOrderTagTypes.getSOByTrackingCode],
  }),
  getSOTrackPosition: builder.query({
    query: (param) => ({
      url: urlcat('/v1/shipper-orders.positions.track', toSnakeCase(param)),
    }),
    providesTags: [shipperOrderTagTypes.getSOTrackPosition],
  }),
  deleteShipperOrder: builder.mutation({
    query: (body) => ({
      body,
      url: '/v1/shipper-orders.delete',
      method: 'post',
    }),
    invalidatesTags: [
      shipperOrderTagTypes.getShipperOrders,
      shipperOrderTagTypes.getShipperOrderInfo,
    ],
  }),
});
// #endregion
