import { MutableRefObject, ReactElement } from 'react';
import { ExtraStyle } from '../component/Type.component';
import { helpCenterUrlAnchorWrapper } from '../util/helpCenter/helpCenter.util';

export enum HelpCenterGeneralTerminologyAnchor {
  LASTMILE = 'last-mile',
  TRANSIT = 'transit',
  TEMPLATE = 'template',
  STANDBY = 'standby',
  PICKUP = 'pickup',
  DROPOFF = 'drop-off',
  DRIVER = 'driver',
  SHIPPER = 'shipper',
}

export enum HelpCenterJOTerminologyAnchor {
  ASSIGNED = 'jo-assigned',
  DELIVERING = 'jo-delivering',
  DRAFT = 'draft',
  DELIVERED = 'jo-delivered',
}

export enum HelpCenterSOTerminologyAnchor {
  INPROCESS = 'in-process',
  ASSIGNED = 'assigned',
  DELIVERING = 'delivering',
  INTRANSIT = 'in-transit',
  DELIVERED = 'delivered',
}

export type HelpCenterImageProps = {
  url: string;
  style?: ExtraStyle;
  onClick?: (imgUrl?: string) => void;
};

export type HelpCenterItemProps = {
  testID?: string;
  description: ReactElement;
  alert?: HelpCenterRulesProps;
  isImageContainerFlexCol?: boolean;
  images?: HelpCenterImageProps[];
  tableRows?: HelpCenterContentTable;
};

export type HelpCenterDataSubItemProps = {
  testID?: string;
  title?: string;
  description?: ReactElement;
  images?: HelpCenterImageProps[];
  isImageContainerFlexCol?: boolean;
  subMeta?: HelpCenterItemProps[];
};

export type HelpCenterDataProps = HelpCenterItemProps & {
  subData?: HelpCenterDataSubItemProps[];
};

export type HelpCenterMetaProps = {
  title?: string;
  metaContentRef?: MutableRefObject<HTMLDivElement | null>;
  metaAnchorRef?: MutableRefObject<HTMLDivElement | null>;
  data?: HelpCenterDataProps[];
};

export type HelpCenterRulesProps = {
  title?: ReactElement;
  description?: ReactElement;
  ruleItems?: ReactElement[];
  position?: 'start' | 'end';
  style?: ExtraStyle;
};

export type HelpCenterContentTable = {
  header: string[];
  datas: string[][];
};

export type HelpCenterContentProps = {
  title?: string;
  testID?: string;
  anchorRef?: MutableRefObject<HTMLDivElement | null>;
  contentRef?: MutableRefObject<HTMLDivElement | null>;
  description?: ReactElement;
  alert?: HelpCenterRulesProps;
  images?: HelpCenterImageProps[];
  isImageContainerFlexCol?: boolean;
  meta: HelpCenterMetaProps[];
};

export type HelpCenterTerminologyTableItem = {
  title?: string;
  contentRef?: MutableRefObject<HTMLDivElement | null>;
  description?: string;
};

export type HelpCenterTerminologyContentProps = {
  title?: string;
  contentRef?: MutableRefObject<HTMLDivElement | null>;
  anchorRef?: MutableRefObject<HTMLDivElement | null>;
  description?: ReactElement;
  content?: HelpCenterTerminologyTableItem[];
};

export type HelpCenterTerminology = {
  url: string;
  title: string;
  subtitle?: string;
  description: string;
};
export type HelpCenterMenuItemProps = {
  label: string;
  isSelected?: boolean;
  onClick(): void;
  subMenu?: HelpCenterMenuItemProps[];
};

export const helpCenterBreadCrumbMapper = (path?: string): string => {
  if (!path) return '';
  const label: Record<string | string, string> = {
    'help-center': 'Job Management Docs',
    'mobile-help-center': 'Job Order App Docs',
    'driver-contest': 'Driver Contest',
    'jo-guides': 'Panduan Perintah Kerja',
    'app-guides': 'Panduan Aplikasi Job Order',
    'driver-app': 'Aplikasi Sopir',
    'add-expense': 'Mengajukan Pengeluaran',
    'data-management': 'Manajemen Data',
    'order-management': 'Manajemen Pesanan',
    'complete-hjo': 'Menyelesaikan Hauling Job Order',
    'complete-jo': 'Menyelesaikan Perintah Kerja',
    'job-order': 'Perintah Kerja',
    'shipper-order': 'Shipper Order',
    'additional-function': 'Fungsionalitas Tambahan',
    'performance-report': 'Melihat Laporan Performa',
    driver: 'Sopir',
    location: 'Lokasi Pengiriman',
    schedule: 'Penjadwalan',
    register: 'Pendaftaran',
    removal: 'Penghapusan',
    'getting-started': 'Memulai',
    terminology: 'Istilah di Job Management',
    'hauling-order': 'Hauling Order',
    DEFAULT: '',
  };

  return label[path] || label.DEFAULT;
};

export const helpCenterIntersectionObserverThreshold = 0.06;

export const helpCenterLocale = 'id-ID';

export const helpCenterScrollIntoViewOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
};

export const helpCenterTerminologyData = {
  general: {
    lastMile: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.LASTMILE,
      ),
      title: 'Last Mile',
      description:
        'Merupakan kondisi dimana Sopir melaksanakan pengiriman ke lokasi Drop-off sebuah Shipper Order.',
    },
    transit: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.TRANSIT,
      ),
      title: 'Transit',
      description:
        'Merupakan kondisi dimana pengiriman berhenti di lokasi yang telah ditentukan sebelum menuju ke lokasi Drop-off.',
    },
    template: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.TEMPLATE,
      ),
      title: 'Templat Pengiriman',
      description:
        'Merupakan formulir pengiriman yang sudah diisi sebelumnya, untuk dipakai kembali ketika membuat Shipper Order.',
    },
    standby: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.STANDBY,
      ),
      title: 'Standby',
      description:
        'Lokasi pemberhentian Sopir untuk bersiap menjalankan tugas selanjutnya.',
    },
    pickUp: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.PICKUP,
      ),
      title: 'Pick Up',
      description: 'Lokasi pengambilan barang.',
    },
    dropOff: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.DROPOFF,
      ),
      title: 'Drop-off',
      subtitle: 'Drop Off',
      description: 'Lokasi tujuan pengiriman barang.',
    },
    driver: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.DRIVER,
      ),
      title: 'Sopir (Driver)',
      subtitle: 'Sopir',
      description: 'Pengemudi yang bertanggung jawab atas pengiriman barang.',
    },
    shipper: {
      url: helpCenterUrlAnchorWrapper(
        HelpCenterGeneralTerminologyAnchor.SHIPPER,
      ),
      title: 'Pengirim (Shipper)',
      description: 'Pemilik barang yang akan dikirimkan.',
    },
  },
  jo: {
    assigned: {
      url: helpCenterUrlAnchorWrapper(HelpCenterJOTerminologyAnchor.ASSIGNED),
      title: 'Ditugaskan',
      description:
        'Perintah Kerja sudah ditugaskan kepada Sopir untuk melaksanakan pengiriman.',
    },
    delivering: {
      url: helpCenterUrlAnchorWrapper(HelpCenterJOTerminologyAnchor.DELIVERING),
      title: 'Dalam Perjalanan',
      description: 'Sopir sedang melaksanakan Perintah Kerja.',
    },
    delivered: {
      url: helpCenterUrlAnchorWrapper(HelpCenterJOTerminologyAnchor.DELIVERED),
      title: 'Terkirim',
      description: 'Semua Shipper Order telah selesai dikerjakan.',
    },
    draft: {
      url: helpCenterUrlAnchorWrapper(HelpCenterJOTerminologyAnchor.DRAFT),
      title: 'Draf',
      description: 'Pembuatan Perintah Kerja yang ditunda.',
    },
  },
  so: {
    inProcess: {
      url: helpCenterUrlAnchorWrapper(HelpCenterSOTerminologyAnchor.INPROCESS),
      title: 'Dalam Proses',
      description:
        'Shipper Order telah berhasil dibuat dan siap untuk ditugaskan pada Perintah Kerja.',
    },
    assigned: {
      url: helpCenterUrlAnchorWrapper(HelpCenterSOTerminologyAnchor.ASSIGNED),
      title: 'Ditugaskan',
      description:
        'Shipper Order telah ditugaskan melalui Perintah Kerja untuk dikirimkan oleh Sopir.',
    },
    delivering: {
      url: helpCenterUrlAnchorWrapper(HelpCenterSOTerminologyAnchor.DELIVERING),
      title: 'Dalam Perjalanan',
      description: 'Aktivitas Shipper Order sedang dilaksanakan oleh Sopir.',
    },
    inTransit: {
      url: helpCenterUrlAnchorWrapper(HelpCenterSOTerminologyAnchor.INTRANSIT),
      title: 'Perjalanan Transit',
      description:
        'Shipper Order sedang dalam perjalanan menuju lokasi Transit.',
    },
    delivered: {
      url: helpCenterUrlAnchorWrapper(HelpCenterSOTerminologyAnchor.DELIVERED),
      title: 'Terkirim',
      description: 'Semua aktivitas pada Shipper Order sudah selesai.',
    },
  },
};
