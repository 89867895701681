import React from 'react';
import tw, { styled } from 'twin.macro';
import { HelpCenterContentProps } from '../../../constant/HelpCenter.constant';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import { Image } from '../../atom';
import {
  BodyFourteen,
  HeadingFour,
  HeadingThree,
} from '../../atom/Text/Text.atom';
import { InfoAlert } from '../../organism';
import HelpCenterDataItem from '../HelpCenterDataItem/HelpCenterDataItem.molecule';

const Container = tw.div`flex flex-col gap-2`;
const HeaderSection = tw.div`flex flex-col gap-2 whitespace-pre-wrap`;

const MetaContainer = tw.div`flex flex-col gap-2`;
const ContentArea = tw.div`flex flex-col gap-6`;

const AnchorArea = tw.div`bg-transparent w-10 absolute h-10 mt-[-72px]`;
const MetaAnchorArea = tw.div`bg-transparent w-10 absolute h-10 mt-[-72px]`;

const ImageSection = styled.div(
  ({ isImageContainerFlexCol }: { isImageContainerFlexCol?: boolean }) => [
    tw`flex gap-4 `,
    isImageContainerFlexCol && tw`flex-col`,
  ],
);
const ImageContainer = styled(Image)(
  ({
    isMobile,
    isImageContainerFlexCol,
  }: { isMobile?: boolean; isImageContainerFlexCol?: boolean }) => [
    tw`flex flex-1 max-h-[360px] border border-beige-lines rounded object-contain bg-neutral-50`,
    isMobile && tw`max-h-[171px] w-2.5`,
    isImageContainerFlexCol && tw`w-full`,
  ],
);

type Props = HelpCenterContentProps;

function HelpCenterContent({
  title,
  description,
  alert,
  meta,
  contentRef,
  anchorRef,
  images,
  testID,
  isImageContainerFlexCol,
}: Props) {
  const { isMobile } = useScreenDetection();

  return (
    <>
      {anchorRef && <AnchorArea ref={anchorRef} />}
      <Container ref={contentRef}>
        <HeaderSection>
          <HeadingThree>{title}</HeadingThree>
          <BodyFourteen>{description}</BodyFourteen>
        </HeaderSection>
        {alert && (!alert?.position || alert?.position === 'start') && (
          <InfoAlert
            rootStyle={alert.style}
            title={alert?.title}
            description={alert?.description}
            rules={alert?.ruleItems}
          />
        )}
        {!!images?.length && (
          <ImageSection isImageContainerFlexCol={isImageContainerFlexCol}>
            {images.map((item, index) => (
              <ImageContainer
                isImageContainerFlexCol={isImageContainerFlexCol}
                onClick={() => item.onClick?.(item.url)}
                isMobile={isMobile}
                css={item.style}
                key={`help-center-content-image-section-${testID || ''}-${
                  item.url || index
                }`}
                src={item.url}
              />
            ))}
          </ImageSection>
        )}

        <ContentArea>
          {meta.map((metaItem, index) => (
            <div
              tw="relative"
              key={`help-center-content-meta-item-${metaItem.title || index}`}
            >
              {metaItem.metaAnchorRef && (
                <MetaAnchorArea ref={metaItem.metaAnchorRef} />
              )}
              <MetaContainer ref={metaItem.metaContentRef}>
                {metaItem?.title && <HeadingFour>{metaItem.title}</HeadingFour>}
                {metaItem.data?.map((dataItem, idx) => (
                  <HelpCenterDataItem
                    idx={idx + 1}
                    tableRows={dataItem.tableRows}
                    alert={dataItem.alert}
                    images={dataItem.images}
                    isImageContainerFlexCol={dataItem.isImageContainerFlexCol}
                    key={`help-center-content-data-item-${metaItem.title}-${idx}`}
                    description={dataItem.description}
                    subData={dataItem.subData}
                  />
                ))}
              </MetaContainer>
            </div>
          ))}
        </ContentArea>
        {alert && alert?.position === 'end' && (
          <InfoAlert
            rootStyle={alert.style}
            title={alert?.title}
            description={alert?.description}
            rules={alert?.ruleItems}
          />
        )}
      </Container>
    </>
  );
}

export default HelpCenterContent;
